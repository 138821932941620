function findOpenedMenuKey(menuItems, url) {
    let activePage = 0;
    for (const item of menuItems)
        if(item.link === undefined){
            if (url.startsWith(item.target.toLowerCase())) activePage = item.key;
        }        
    return activePage | 0;

}

export default findOpenedMenuKey;