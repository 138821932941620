import React from "react";
import './CoverImage.css'

function CoverImage(props){   
    return(
            <div className="text-black w-100 h-100" id="cover">
                {props.img && <img src={props.img} className="imgcover" alt={props.title}/>}
                <div className="w-100 bg-light" style={{"position": "absolute", "bottom": "0"}}>
                    <h4>{props.title}</h4>
                </div>        
            </div>
    )
}

export default CoverImage;