import React from 'react';
import FeatureCard from '../components/FeatureCard';
import SplitScreenRenderer from '../components/SplitscreenRenderer';
import FeatureDescription from '../components/FeatureDescription';
import { Box, Container, Divider, Grid, ImageList, ImageListItem, ImageListItemBar, Typography } from '@material-ui/core';
import './Features.css';

import {FeatureData} from '../data/features/Features.js';

import { FeatureCardPage, FeatureCardContentType } from '../components/FeatureCardPage';
import { ModalGateway } from 'react-images';
import {connect} from 'react-redux';

class Features extends React.Component {
    constructor() {
        super();
        this.state = {
            cards: [
                ...FeatureData
            ]
        }

        this.printCard = (card, index, cards) => {
            //2022.03, LL, get cover image for feature card 
            let coverImg = card.cover;

            //2022.03, LL, hide feature card base on the "hidden" property
            let result;
            if(card.hidden) {
                result = null;
            }
            else{
                
                result = (
                    <Grid item key={"card"+index} md={card.active ? 12 : 4} sm={card.active ? 12 : 6} xs={card.active ? 12 : 12}>
                        <FeatureCard index={index} coverImg={coverImg} header={card.header} isActive={card.active} onFlip={((newState) => this.cardFlipped(index, newState))}>
                            { card.elements ? card.elements.map(this.printElement.bind(this, card)) : null }
                        </FeatureCard>
                    </Grid>
                )
            }
            return result;
        };
    }

    printImageList(card, element, index) {
        const printImage = (image, index) => {
            return (
                // make image big when in small screen
                <ImageListItem className="border" style={this.props.isXSmall? {height: "100%",width:"100%"} : {height: "100%"}} key={"image-"+index} rows={element.images.length}>
                    <img style={{padding: "1em", objectFit: "contain"}} height="75%" width={(100.0 / element.images.length).toString() + "%"} src={image.src} alt={image.alt}></img>
                    <ImageListItemBar title={image.title} />
                </ImageListItem>
            );
        }
        const images = element.images.map(printImage);
        return(
            <FeatureCardPage label={element.label} key={"feature-description-"+index}>
                <ImageList style={{height: "100%"}}>
                    {images}
                </ImageList>
            </FeatureCardPage>
        )
    }

    printDescription(card, element, index) {
        const imports = card.imports;
        const name = card.name;
        let toFeatureDescription = (content, index) => {
            return(<FeatureDescription content={content} imports={imports} featureName={name} key={"feature-description-"+index}></FeatureDescription>);
        }

        let content = Array.isArray(element.content) ? element.content.map(toFeatureDescription) : toFeatureDescription(element.content);
        return(
            <FeatureCardPage label={element.label} key={"feature-card-page-"+index}>
                {content}
            </FeatureCardPage>
        )
    }

    printSplitscreenRenderer(card, element, index) {
        return(
            <FeatureCardPage label={element.label} key={"feature-card-page-"+index}>
                <SplitScreenRenderer mesh1={element.mesh1} mesh2={element.mesh2}></SplitScreenRenderer>
            </FeatureCardPage>
        );
    }

    printElement(card, element, index) {

        switch(element.type) {
            case FeatureCardContentType.IMAGE_LIST: return this.printImageList(card, element, index);
            case FeatureCardContentType.SPLITSCREEN_RENDERER: return this.printSplitscreenRenderer(card, element, index);
            case FeatureCardContentType.DESCRIPTION: return this.printDescription(card, element, index);
            default: return <Typography>Hää</Typography>;
        }
    }

    cardFlipped(cardIndex, newState) {
        let newCards = this.state.cards;
        newCards[cardIndex].active = newState;
        this.setState({
            cards: newCards,
            ...this.state
        });
    };

    render() {
        return (
            <Container>        
                {/* <Box sx={{paddingTop: "2em", paddingBottom: "2em"}}><Divider/></Box> */}
                    <Typography variant="h2">Features</Typography>
                <Box sx={{paddingTop: "2em", paddingBottom: "2em"}}><Divider /></Box>
                <Grid
                    container 
                    direction="row"
                    justify-content="flex-start"
                    align-items="center"
                    spacing={2}
                    className="mainGrid"
                >
                    { this.state.cards.map(this.printCard.bind(this)) }
                </Grid>
                <ModalGateway>
                </ModalGateway>
            </Container>
        )
    }
}

// to allow class to access the shared state
function mapStateToProps(state) {
    const isXSmall = state.isXSmall;
    return {isXSmall};
}

export default connect(mapStateToProps)(Features);
