import './DesignGuidelines.css'
import { Box, Container, Divider, Typography } from '@material-ui/core';
import guidelines_pdf from '../data/design_guidelines/2020-06-09_Design-Guidelines-Overlapping_web.pdf'
import blender_tut1 from '../data/design_guidelines/Blender_Tut_1440p_V5_1.mp4'
import blender_tut2 from '../data/design_guidelines/Blender_Tut_1440p_V5_2.mp4'
export default function DesignGuidelines() {
    return (
        <Container>
            {/* <Box sx={{paddingTop: "2em", paddingBottom: "2em"}}><Divider/></Box> */}
                <Typography variant="h2">Design Guidelines</Typography>
            <Box sx={{paddingTop: "2em", paddingBottom: "2em"}}><Divider /></Box>
            <Typography>Our written design guidelines can be downloaded as a PDF <a href={guidelines_pdf} target="_blank" rel="noreferrer">here</a></Typography>
            
            <Box sx={{paddingTop: "2em", paddingBottom: "2em"}}><Divider /></Box>
            <Typography variant="h6">Designing Nested Objects For Cuttlefish In Blender - Creating The Geometry In Blender</Typography>
            <video src={blender_tut1} width="100%"controls="true">
                Sorry, your browser doesn't support embedded videos,
                but you can <a href={blender_tut1}>download it</a>
                and watch it with your favorite video player!
            </video>
            
            <Box sx={{paddingTop: "2em", paddingBottom: "2em"}}><Divider /></Box>
            <Typography variant="h6">Designing Nested Objects For Cuttlefish In Blender - Creating A Texture With Varying Alpha in Photoshop</Typography>

            <video src={blender_tut2} width="100%" controls="true">
                Sorry, your browser doesn't support embedded videos,
                but you can <a href={blender_tut2}>download it</a>
                and watch it with your favorite video player!
            </video>
        </Container>
    )
}