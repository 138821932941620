import { Box, Container, Typography, Divider} from '@material-ui/core'
import './DataProtectionInformation.css'

export default function DataProtectionInformation() {
    return (
        <Container>
            {/* <Box sx={{paddingTop: "2em", paddingBottom: "2em"}}><Divider/></Box> */}
            <Typography variant="h2">Data Protection Information</Typography>
            <Box sx={{paddingTop: "2em", paddingBottom: "2em"}}><Divider/></Box>
            <Typography>This data protection  information applies to data processing on all websites of the [FhG-Institute] that refer to this data protection information. The controller is:</Typography>
            <Box sx={{m:5}}>
                <Typography variant="h6">Fraunhofer-Gesellschaft zur Förderung der angewandten Forschung e.V.</Typography>
                <Typography>Hansastraße 27 c, </Typography>
                <Typography>80686 München, Germany</Typography>
                <Typography>on behalf of its Fraunhofer Institute for Computer Graphics Research IGD</Typography>
                <Typography>(hereinafter referred to as “Fraunhofer-IGD”)</Typography>
            
                <Typography>Email: <a href="mailto:info@igd.fraunhofer.de">info@igd.fraunhofer.de</a></Typography>
                <Typography>Telephone: +49 6151 155-0</Typography>
                <Typography>Fax: +49 6151 155-199</Typography>

            </Box>
            <Box>
                <Typography>The corporate data protection officer at Fraunhofer can be reached at the above-mentioned address, c/o Data Protection Officer or at <a href="mailto:datenschutz@zv.fraunhofer.de">datenschutz@zv.fraunhofer.de</a>.</Typography>
                <Typography>Please feel free to contact the data protection officer directly at any time if you have questions concerning data protection law and/or your rights as data subject.</Typography>
            </Box>            
            <Box sx={{paddingTop: "2em", paddingBottom: "2em"}}><Divider /></Box>
            <Box>
                <Typography variant="h4">Personal Data Processing and Purposes of Data Processing</Typography>
                <Typography>
                    <Box sx={{m:2, marginLeft: 0}}>You may access our website without having to disclose any details on your identity. The browser installed on your device merely transmits information automatically to the server of our website (e.g., browser type and version, date and time of access) to enable a connection with the website. This includes the IP address of your requesting device. This information is temporarily stored in a so-called log file and will be deleted after 7 days.</Box>
                    <Box sx={{m:2, marginLeft: 0}}>Your IP address is processed for technical and administrative purposes  regarding connection set-up and stability, to guarantee the security and functioning of our website and, if necessary, to be able to track any illegal attacks on the website.</Box>
                    <Box sx={{m:2, marginLeft: 0}}>The legal basis for processing the IP address is Art. 6 (1) lit. f GDPR. Our legitimate interest ensues from said security interest and the necessity of the unobstructed availability of our website.</Box>
                    <Box sx={{m:2, marginLeft: 0}}>We cannot draw any direct conclusions about your identity from processing the IP address and other information in the log file.</Box>
                </Typography>
            </Box>
            <Box sx={{paddingTop: "2em", paddingBottom: "2em"}}><Divider /></Box>
            <Typography variant="h4">Transfer of Data</Typography>
            <Typography>
            <Box sx={{m: 2, marginLeft: 0}}>If we transfer personal data collected through websites to processors , we will notify you in this data protection information regarding the respective data processing operation, citing the specific recipient. Aside from that, we will transfer your personal data only if</Box>
            <Box sx={{m:2, marginLeft: 0}}>
                <ul>
                    <li>you have given explicit consent pursuant to Art. 6 (1) lit. a GDPR;</li>
                    <li>this is necessary pursuant to Article 6 (1) lit. b GDPR for the performance of a contract with you (for example, transfer to shipping companies for the purpose of delivering goods ordered by you, or transmitting payment information to payment service providers or credit institutions in order to process a payment transaction);</li>
                    <li>there is a legal obligation for transfer pursuant to Art. 6 (1) lit. c GDPR.</li>
                </ul>
            
            </Box>
            <Box sx={{m:2, marginLeft: 0}}>The recipients must not use the transferred data for any purposes other than the above-mentioned ones.</Box>
            </Typography>
            <Box sx={{paddingTop: "2em", paddingBottom: "2em"}}><Divider/></Box>
            
            <Typography variant="h4">8.	Rights of the Data Subject</Typography>
            <Box sx={{m:2, marginLeft: 0}}>
                <Typography>You have the right: </Typography>
                <Typography>
                    <ul>
                        <li>pursuant to Art. 7(3) GDPR, to withdraw your consent at any time. This means that we may not continue the data processing based on this consent in the future;</li>
                        <li>pursuant to Art. 15 GDPR, to obtain access to your personal data processed by us. In particular, you may request information about the purposes of the processing, the categories of personal data concerned, the categories of recipients to whom the personal data have been or will be disclosed, and the envisaged period for which the data will be stored. Moreover, you have the right to request rectification, erasure, or restriction of processing, to object to processing, the right to lodge a complaint, and to obtain information about the source of your data if they were not collected by us as well as about the existence of automated decision-making, including profiling, and, if applicable, meaningful information about the details involved;</li>
                        <li>pursuant to Art. 16 GDPR, to obtain without undue delay the rectification of inaccurate data or the completion of your personal data stored by us;</li>
                        <li>pursuant to Art. 17 GDPR, to obtain the erasure of personal data stored by us unless processing is necessary to exercise the right of freedom of expression and information, to comply with a legal obligation, for reasons of public interest, or to establish, exercise or defend legal claims;</li>
                        <li>pursuant to Art. 18 GDPR, to obtain restriction of processing of your personal data if you contest the accuracy of the data, the processing is unlawful but you oppose the erasure of the personal data, or if we no longer need the personal data while you still require it for establishing, exercising or defending legal claims, or if you have filed an objection to the processing pursuant to Art. 21 GDPR;</li>
                        <li>pursuant to Art. 20 GDPR, to receive your personal data that you have provided to us, in a structured, commonly used and machine-readable format or to demand the transfer of those data to another controller and</li>
                        <li>pursuant to Art. 77 GDPR, the right to lodge a complaint with a supervisory authority. Generally, you may contact the supervisory authority of your usual residence, place of work or the registered offices of our organization.</li>
                    </ul>
                </Typography>
            </Box>
            <Box sx={{m: 10, border: "2px", borderColor: "black", borderStyle: "solid", p: 2, marginBottom: "2em", marginTop: "2em"}}>
            <Typography variant="h5">Information on your right to object pursuant to Art. 21 GDPR</Typography>            
            <Typography>
                <Box sx={{m:2}}>You have the right to object, on grounds relating to your particular situation, at any time to processing of your personal data pursuant to Art. 6 (1) lit. e GDPR (data processing carried out in the public interest) and Art. 6 (1) lit. f GDPR (data processing for purposes of legitimate interests). This also applies to any profiling as defined in Art. 4 (4) GDPR that is based on said provision in Art. 6.</Box>
                <Box sx={{m:2}}>If you file an objection, we will no longer process your personal data unless we can demonstrate compelling legitimate grounds for processing that override your interests, rights and freedoms, or unless the processing serves the establishment, exercise or defense of legal claims.</Box>
                <Box sx={{m:2}}>If your objection is directed against the processing of data for the purpose of direct advertising, we will stop the processing immediately. In this case, citing a special situation is not necessary. This includes profiling to the extent that it is related to such direct advertising.</Box>
                <Box sx={{m:2}}>If you would like to assert your right to object, simply send an email to <a href="mailto:datenschutzkoordination@zv.fraunhofer.de.">datenschutzkoordination@zv.fraunhofer.de</a>.</Box>
            </Typography>
            </Box>
            <Box sx={{paddingTop: "2em", paddingBottom: "2em"}}><Divider/></Box>

            <Typography variant="h4">Data Security</Typography>
            
            <Typography>
                <Box sx={{m:2, marginLeft: 0}}>All your personal data are transmitted in encrypted format, using the widely used and secure TLS (Transport Layer Security) standard. TLS is a secure and proven standard that is also used, for instance, in online banking. You will recognize a secure TLS connection by the additional s after http (i.e., https://...) in the address bar of your browser or by the lock icon in the bottom part of your browser window.</Box>
                <Box sx={{m:2, marginLeft: 0}}>In all other regards, we use suitable technical and organizational security measures to protect your data against accidental or intentional manipulations, partial or complete loss, destruction, or the unauthorized access of third parties. We continuously improve our security measures in accordance with the state of the art.</Box>
            </Typography>
            <Typography>Timeliness and Amendments to this Data Protection Information</Typography>
            <Typography><Box sx={{m:2, marginLeft: 0}}>The further development of our website and the products and services offered or changed due to statutory or regulatory requirements, respectively, may make it necessary to amend this data protection information. You may access and print out the latest data protection information at any time from our website.</Box></Typography>
            <Box sx={{paddingTop: "2em", paddingBottom: "2em"}}><Divider/></Box>
        </Container>
    )
}