import AdaptiveThickening_description_md from './AdaptiveThickening/description.md';
import AdaptiveThickening_AdaptiveThickening_Print_color_jpg from './AdaptiveThickening/AdaptiveThickening_Print_color.jpg';
import AdaptiveThickening_AdaptiveThickening_Print_clear_jpg from './AdaptiveThickening/AdaptiveThickening_Print_clear.jpg';
import AdaptiveThickening_arm_not_thickened_small_ply from './AdaptiveThickening/arm_not_thickened_small.ply';
import AdaptiveThickening_arm_thickened_small_ply from './AdaptiveThickening/arm_thickened_small.ply';
import AdaptiveThickening_feature_json from './AdaptiveThickening/feature.json';
import AutomaticNesting_description_md from './AutomaticNesting/description.md';
import AutomaticNesting_AdvancedPacking_png from './AutomaticNesting/AdvancedPacking.png';
import AutomaticNesting_BoundingBoxPacking_png from './AutomaticNesting/BoundingBoxPacking.png';
import AutomaticNesting_feature_json from './AutomaticNesting/feature.json';
import AutomaticSlicing_description_md from './AutomaticSlicing/description.md';
import AutomaticSlicing_Automatic_Slicing_png from './AutomaticSlicing/Automatic_Slicing.png';
import AutomaticSlicing_feature_json from './AutomaticSlicing/feature.json';
import ColorAndTranslucencyPrinting_description_md from './ColorAndTranslucencyPrinting/description.md';
import ColorAndTranslucencyPrinting_Anatomy_png from './ColorAndTranslucencyPrinting/Anatomy.png';
import ColorAndTranslucencyPrinting_Head_png from './ColorAndTranslucencyPrinting/Head.png';
import ColorAndTranslucencyPrinting_Ship_png from './ColorAndTranslucencyPrinting/Ship.png';
import ColorAndTranslucencyPrinting_HessenLion_FhG_crop810_jpg from './ColorAndTranslucencyPrinting/HessenLion_FhG_crop810.jpg';
import ColorAndTranslucencyPrinting_Lucy_front_crop_jpg from './ColorAndTranslucencyPrinting/Lucy_front_crop.jpg';
import ColorAndTranslucencyPrinting_feature_json from './ColorAndTranslucencyPrinting/feature.json';
import CurvedTriangles_description_md from './CurvedTriangles/description.md';
import CurvedTriangles_chesspiece_md from './CurvedTriangles/chesspiece.md';
import CurvedTriangles_sphere_md from './CurvedTriangles/sphere.md';
import CurvedTriangles_ChessPiece_curved_JPG from './CurvedTriangles/ChessPiece_curved.JPG';
import CurvedTriangles_ChessPiece_curved_close_JPG from './CurvedTriangles/ChessPiece_curved_close.JPG';
import CurvedTriangles_ChessPiece_flat_JPG from './CurvedTriangles/ChessPiece_flat.JPG';
import CurvedTriangles_ChessPiece_flat_close_JPG from './CurvedTriangles/ChessPiece_flat_close.JPG';
import CurvedTriangles_Sphere_curved_3cm_coin_JPG from './CurvedTriangles/Sphere_curved_3cm_coin.JPG';
import CurvedTriangles_Sphere_curved_5cm_coin_JPG from './CurvedTriangles/Sphere_curved_5cm_coin.JPG';
import CurvedTriangles_Sphere_flat_3cm_coin_JPG from './CurvedTriangles/Sphere_flat_3cm_coin.JPG';
import CurvedTriangles_coarse_sphere_mesh_png from './CurvedTriangles/coarse_sphere_mesh.png';
import CurvedTriangles_feature_json from './CurvedTriangles/feature.json';
import CurvedTriangles_spiderman_flat_PN_png from './CurvedTriangles/spiderman_flat_PN.png';
import Displacement_description_md from './Displacement/description.md';
import Displacement_usage_md from './Displacement/usage.md';
import Displacement_earth_md from './Displacement/earth.md';
import Displacement_Earth__0_2_displacement_JPG from './Displacement/Earth_-0.2_displacement.JPG';
import Displacement_Earth__0_2_displacement_crop_jpg from './Displacement/Earth_-0.2_displacement_crop.jpg';
import Displacement_Earth_0_2_displacement_JPG from './Displacement/Earth_0.2_displacement.JPG';
import Displacement_Footballs_JPG from './Displacement/Footballs.JPG';
import Displacement_Notes_txt from './Displacement/Notes.txt';
import Displacement_Teapot_Cuttlefish_JPG from './Displacement/Teapot_Cuttlefish.JPG';
import Displacement_Teapot_dishCloth_displacement_JPG from './Displacement/Teapot_dishCloth_displacement.JPG';
import Displacement_Teapot_dishCloth_noDisplacement_JPG from './Displacement/Teapot_dishCloth_noDisplacement.JPG';
import Displacement_feature_json from './Displacement/feature.json';
import GoniochromaticEffect_description_md from './GoniochromaticEffect/description.md';
import GoniochromaticEffect_feature_json from './GoniochromaticEffect/feature.json';
import Hollowing_description_md from './Hollowing/description.md';
import Hollowing_savings_md from './Hollowing/savings.md';
import Hollowing_brain_slices_solid_hollow_png from './Hollowing/brain_slices_solid_hollow.png';
import Hollowing_feature_json from './Hollowing/feature.json';
import Hollowing_snapshot00_png from './Hollowing/snapshot00.png';
import MeshOutput_description_md from './MeshOutput/description.md';
import MeshOutput_Mesh_Output_png from './MeshOutput/Mesh_Output.png';
import MeshOutput_feature_json from './MeshOutput/feature.json';
import MillionsOfTriangles_description_md from './MillionsOfTriangles/description.md';
import MillionsOfTriangles_benchmark_md from './MillionsOfTriangles/benchmark.md';
import MillionsOfTriangles_lucy01_png from './MillionsOfTriangles/lucy01.png';
import MillionsOfTriangles_lucy02_png from './MillionsOfTriangles/lucy02.png';
import MillionsOfTriangles_Lucy_front_crop_jpg from './MillionsOfTriangles/Lucy_front_crop.jpg';
import MillionsOfTriangles_xyzrgb_dragon_00_png from './MillionsOfTriangles/xyzrgb_dragon_00.png';
import MillionsOfTriangles_xyzrgb_dragon_01_png from './MillionsOfTriangles/xyzrgb_dragon_01.png';
import MillionsOfTriangles_Dragon_WaxColor_jpg from './MillionsOfTriangles/Dragon_WaxColor.jpg';
import MillionsOfTriangles_victimes_00_jpg from './MillionsOfTriangles/victimes_00.jpg';
import MillionsOfTriangles_victimes_01_jpg from './MillionsOfTriangles/victimes_01.jpg';
import MillionsOfTriangles_Victimes_jpg from './MillionsOfTriangles/Victimes.jpg';
import MillionsOfTriangles_Notes_txt from './MillionsOfTriangles/Notes.txt';
import MillionsOfTriangles_feature_json from './MillionsOfTriangles/feature.json';
import MillionsOfTriangles_victimes_02_jpg from './MillionsOfTriangles/victimes_02.jpg';
import RobustVoxelization_description_md from './RobustVoxelization/description.md';
import RobustVoxelization_armadillo_holes_input_slice192_dsdf_1_jpg from './RobustVoxelization/armadillo_holes_input_slice192_dsdf_1.jpg';
import RobustVoxelization_armadillo_holes_points_png from './RobustVoxelization/armadillo_holes_points.png';
import RobustVoxelization_armadillo_holes_points_dsdf_png from './RobustVoxelization/armadillo_holes_points_dsdf.png';
import RobustVoxelization_Kopernicus_render_1_png from './RobustVoxelization/Kopernicus_render_1.png';
import RobustVoxelization_Kopernicus_render_5_jpg from './RobustVoxelization/Kopernicus_render_5.jpg';
import RobustVoxelization_Kopernicus_render_6_jpg from './RobustVoxelization/Kopernicus_render_6.jpg';
import RobustVoxelization_Kopernicus_jpg from './RobustVoxelization/Kopernicus.jpg';
import RobustVoxelization_victimes_render_jpg from './RobustVoxelization/victimes_render.jpg';
import RobustVoxelization_Victimes_jpg from './RobustVoxelization/Victimes.jpg';
import RobustVoxelization_porto_carmo_render_jpg from './RobustVoxelization/porto_carmo_render.jpg';
import RobustVoxelization_PortoCarmo_jpg from './RobustVoxelization/PortoCarmo.jpg';
import RobustVoxelization_feature_json from './RobustVoxelization/feature.json';
import SurfaceDither_description_md from './SurfaceDither/description.md';
import SurfaceDither_usage_md from './SurfaceDither/usage.md';
import SurfaceDither_cube_md from './SurfaceDither/cube.md';
import SurfaceDither_1_beerista_arm_close_hblue_JPG from './SurfaceDither/1_beerista_arm_close_hblue.JPG';
import SurfaceDither_1_beerista_arm_close_hred_JPG from './SurfaceDither/1_beerista_arm_close_hred.JPG';
import SurfaceDither_1_beerista_legs_hred_JPG from './SurfaceDither/1_beerista_legs_hred.JPG';
import SurfaceDither_1_spiritbase_hblue_JPG from './SurfaceDither/1_spiritbase_hblue.JPG';
import SurfaceDither_1_spiritsnow_hred_JPG from './SurfaceDither/1_spiritsnow_hred.JPG';
import SurfaceDither_4_beerista_arm_close_hblue_JPG from './SurfaceDither/4_beerista_arm_close_hblue.JPG';
import SurfaceDither_4_beerista_arm_close_hred_JPG from './SurfaceDither/4_beerista_arm_close_hred.JPG';
import SurfaceDither_4_beerista_legs_hred_JPG from './SurfaceDither/4_beerista_legs_hred.JPG';
import SurfaceDither_4_spiritbase_hblue_JPG from './SurfaceDither/4_spiritbase_hblue.JPG';
import SurfaceDither_4_spiritsnow_hred_JPG from './SurfaceDither/4_spiritsnow_hred.JPG';
import SurfaceDither_SurfaceDitheringfor3DPinting_ecaa6606_jpg from './SurfaceDither/SurfaceDitheringfor3DPinting.ecaa6606.jpg';
import SurfaceDither_beer1_JPG from './SurfaceDither/beer1.JPG';
import SurfaceDither_beer4_JPG from './SurfaceDither/beer4.JPG';
import SurfaceDither_cube1_JPG from './SurfaceDither/cube1.JPG';
import SurfaceDither_cube4_JPG from './SurfaceDither/cube4.JPG';
import SurfaceDither_feature_json from './SurfaceDither/feature.json';
import TextureDetailPreservation_description_md from './TextureDetailPreservation/description.md';
import TextureDetailPreservation_usage_md from './TextureDetailPreservation/usage.md';
import TextureDetailPreservation_feature_json from './TextureDetailPreservation/feature.json';
import TextureDetailPreservation_normal_JPG from './TextureDetailPreservation/normal.JPG';
import TextureDetailPreservation_sharp_JPG from './TextureDetailPreservation/sharp.JPG';
import TextureDetailPreservation_two_vases_JPG from './TextureDetailPreservation/two_vases.JPG';
import VoxelInput_description_md from './VoxelInput/description.md';
import VoxelInput_VoxelInput_png from './VoxelInput/VoxelInput.png';
import VoxelInput_feature_json from './VoxelInput/feature.json';

export const FeatureData = [{
    "active": false,
    "header": "Adaptive Thickening",
    "cover": AdaptiveThickening_AdaptiveThickening_Print_clear_jpg,
    "hidden": false,
    "path": "/app/src/data/features/AdaptiveThickening",
    "name": "AdaptiveThickening",
    "elements": [{
        "type": "Description",
        "label": "Description",
        "content": AdaptiveThickening_description_md
    }, {
        "type": "ImageList",
        "images": [{
            "src": AdaptiveThickening_AdaptiveThickening_Print_color_jpg,
            "title": "3D color printed arm adaptively thickened by adding colored material.",
            "alt": "3D Color Print that shows an arm bone and veins that have been adaptively thickened by using more of the same color so that they can be printed.",
            "width": 14,
            "height": 3.7
        }, {
            "src": AdaptiveThickening_AdaptiveThickening_Print_clear_jpg,
            "title": "3D color printed arm adaptively thickened by adding transparent material.",
            "alt": "3D Color Print that shows an arm bone and veins that have been adaptively thickened by using clear amterial so that they can be printed.",
            "width": 14,
            "height": 3.7
        }],
        "label": "Examples"
    }, {
        "type": "SplitScreenRenderer",
        "label": "Interactive Visualization",
        "mesh1": AdaptiveThickening_arm_not_thickened_small_ply,
        "mesh2": AdaptiveThickening_arm_thickened_small_ply
    }],
    "imports": {
        "AdaptiveThickening_description_md": AdaptiveThickening_description_md,
        "AdaptiveThickening_AdaptiveThickening_Print_color_jpg": AdaptiveThickening_AdaptiveThickening_Print_color_jpg,
        "AdaptiveThickening_AdaptiveThickening_Print_clear_jpg": AdaptiveThickening_AdaptiveThickening_Print_clear_jpg,
        "AdaptiveThickening_arm_not_thickened_small_ply": AdaptiveThickening_arm_not_thickened_small_ply,
        "AdaptiveThickening_arm_thickened_small_ply": AdaptiveThickening_arm_thickened_small_ply,
        "AdaptiveThickening_feature_json": AdaptiveThickening_feature_json
    }
}, {
    "active": false,
    "header": "Automatic Nesting",
    "cover": AutomaticNesting_AdvancedPacking_png,
    "hidden": false,
    "path": "/app/src/data/features/AutomaticNesting",
    "name": "AutomaticNesting",
    "elements": [{
        "type": "Description",
        "label": "Description",
        "content": AutomaticNesting_description_md
    }, {
        "type": "ImageList",
        "images": [{
            "src": AutomaticNesting_AdvancedPacking_png,
            "title": "3D print tray packed using the advanced packing.",
            "alt": "3D print tray packed using the advanced packing",
            "width": 9.23,
            "height": 9.23
        }, {
            "src": AutomaticNesting_BoundingBoxPacking_png,
            "title": "3D print tray packed using bounding box packing",
            "alt": "3D print tray packed using bounding box packing",
            "width": 10.02,
            "height": 10.02
        }],
        "label": "Examples"
    }],
    "imports": {
        "AutomaticNesting_description_md": AutomaticNesting_description_md,
        "AutomaticNesting_AdvancedPacking_png": AutomaticNesting_AdvancedPacking_png,
        "AutomaticNesting_BoundingBoxPacking_png": AutomaticNesting_BoundingBoxPacking_png,
        "AutomaticNesting_feature_json": AutomaticNesting_feature_json
    }
}, {
    "active": false,
    "header": "Automatic Slicing",
    "cover": AutomaticSlicing_Automatic_Slicing_png,
    "hidden": false,
    "path": "/app/src/data/features/AutomaticSlicing",
    "name": "AutomaticSlicing",
    "elements": [{
        "type": "Description",
        "label": "Description",
        "content": AutomaticSlicing_description_md
    }],
    "imports": {
        "AutomaticSlicing_description_md": AutomaticSlicing_description_md,
        "AutomaticSlicing_Automatic_Slicing_png": AutomaticSlicing_Automatic_Slicing_png,
        "AutomaticSlicing_feature_json": AutomaticSlicing_feature_json
    }
}, {
    "active": false,
    "header": "Color And Translucency Printing",
    "cover": ColorAndTranslucencyPrinting_Anatomy_png,
    "hidden": false,
    "path": "/app/src/data/features/ColorAndTranslucencyPrinting",
    "name": "ColorAndTranslucencyPrinting",
    "elements": [{
        "type": "Description",
        "label": "Description",
        "content": ColorAndTranslucencyPrinting_description_md
    }, {
        "type": "ImageList",
        "images": [{
            "src": ColorAndTranslucencyPrinting_Anatomy_png,
            "title": "Anatomy model.",
            "alt": "Anatomy model",
            "width": 9.33,
            "height": 5.38
        }, {
            "src": ColorAndTranslucencyPrinting_Head_png,
            "title": "Head model.",
            "alt": "Head model",
            "width": 10.8,
            "height": 5.25
        }, {
            "src": ColorAndTranslucencyPrinting_Ship_png,
            "title": "Ship model",
            "alt": "The left is printed with the transluency to match the wax (right).",
            "width": 3.73,
            "height": 2.57
        }],
        "label": "Examples"
    }],
    "imports": {
        "ColorAndTranslucencyPrinting_description_md": ColorAndTranslucencyPrinting_description_md,
        "ColorAndTranslucencyPrinting_Anatomy_png": ColorAndTranslucencyPrinting_Anatomy_png,
        "ColorAndTranslucencyPrinting_Head_png": ColorAndTranslucencyPrinting_Head_png,
        "ColorAndTranslucencyPrinting_Ship_png": ColorAndTranslucencyPrinting_Ship_png,
        "ColorAndTranslucencyPrinting_HessenLion_FhG_crop810_jpg": ColorAndTranslucencyPrinting_HessenLion_FhG_crop810_jpg,
        "ColorAndTranslucencyPrinting_Lucy_front_crop_jpg": ColorAndTranslucencyPrinting_Lucy_front_crop_jpg,
        "ColorAndTranslucencyPrinting_feature_json": ColorAndTranslucencyPrinting_feature_json
    }
}, {
    "active": false,
    "header": "Curved Triangles",
    "cover": CurvedTriangles_ChessPiece_curved_JPG,
    "hidden": false,
    "path": "/app/src/data/features/CurvedTriangles",
    "name": "CurvedTriangles",
    "elements": [{
        "type": "Description",
        "label": "Description",
        "content": CurvedTriangles_description_md
    }, {
        "type": "Description",
        "label": "Example - Chess Piece",
        "content": CurvedTriangles_chesspiece_md
    }, {
        "type": "Description",
        "label": "Example - Sphere",
        "content": CurvedTriangles_sphere_md
    }],
    "imports": {
        "CurvedTriangles_description_md": CurvedTriangles_description_md,
        "CurvedTriangles_chesspiece_md": CurvedTriangles_chesspiece_md,
        "CurvedTriangles_sphere_md": CurvedTriangles_sphere_md,
        "CurvedTriangles_ChessPiece_curved_JPG": CurvedTriangles_ChessPiece_curved_JPG,
        "CurvedTriangles_ChessPiece_curved_close_JPG": CurvedTriangles_ChessPiece_curved_close_JPG,
        "CurvedTriangles_ChessPiece_flat_JPG": CurvedTriangles_ChessPiece_flat_JPG,
        "CurvedTriangles_ChessPiece_flat_close_JPG": CurvedTriangles_ChessPiece_flat_close_JPG,
        "CurvedTriangles_Sphere_curved_3cm_coin_JPG": CurvedTriangles_Sphere_curved_3cm_coin_JPG,
        "CurvedTriangles_Sphere_curved_5cm_coin_JPG": CurvedTriangles_Sphere_curved_5cm_coin_JPG,
        "CurvedTriangles_Sphere_flat_3cm_coin_JPG": CurvedTriangles_Sphere_flat_3cm_coin_JPG,
        "CurvedTriangles_coarse_sphere_mesh_png": CurvedTriangles_coarse_sphere_mesh_png,
        "CurvedTriangles_feature_json": CurvedTriangles_feature_json,
        "CurvedTriangles_spiderman_flat_PN_png": CurvedTriangles_spiderman_flat_PN_png
    }
}, {
    "active": false,
    "header": "Displacement",
    "cover": Displacement_Earth__0_2_displacement_crop_jpg,
    "hidden": false,
    "path": "/app/src/data/features/Displacement",
    "name": "Displacement",
    "elements": [{
        "type": "Description",
        "label": "Description",
        "content": Displacement_description_md
    }, {
        "type": "Description",
        "label": "Usage",
        "content": Displacement_usage_md
    }, {
        "type": "Description",
        "label": "Example - Earth",
        "content": Displacement_earth_md
    }],
    "imports": {
        "Displacement_description_md": Displacement_description_md,
        "Displacement_usage_md": Displacement_usage_md,
        "Displacement_earth_md": Displacement_earth_md,
        "Displacement_Earth__0_2_displacement_JPG": Displacement_Earth__0_2_displacement_JPG,
        "Displacement_Earth__0_2_displacement_crop_jpg": Displacement_Earth__0_2_displacement_crop_jpg,
        "Displacement_Earth_0_2_displacement_JPG": Displacement_Earth_0_2_displacement_JPG,
        "Displacement_Footballs_JPG": Displacement_Footballs_JPG,
        "Displacement_Notes_txt": Displacement_Notes_txt,
        "Displacement_Teapot_Cuttlefish_JPG": Displacement_Teapot_Cuttlefish_JPG,
        "Displacement_Teapot_dishCloth_displacement_JPG": Displacement_Teapot_dishCloth_displacement_JPG,
        "Displacement_Teapot_dishCloth_noDisplacement_JPG": Displacement_Teapot_dishCloth_noDisplacement_JPG,
        "Displacement_feature_json": Displacement_feature_json
    }
}, {
    "active": false,
    "header": "Goniochromatic Effect",
    "cover": null,
    "hidden": true,
    "published": false,
    "path": "/app/src/data/features/GoniochromaticEffect",
    "name": "GoniochromaticEffect",
    "elements": [{
        "type": "Description",
        "label": "Description",
        "content": GoniochromaticEffect_description_md
    }],
    "imports": {
        "GoniochromaticEffect_description_md": GoniochromaticEffect_description_md,
        "GoniochromaticEffect_feature_json": GoniochromaticEffect_feature_json
    }
}, {
    "active": false,
    "header": "Hollowing",
    "cover": Hollowing_brain_slices_solid_hollow_png,
    "hidden": false,
    "path": "/app/src/data/features/Hollowing",
    "name": "Hollowing",
    "elements": [{
        "type": "Description",
        "label": "Description",
        "content": Hollowing_description_md
    }, {
        "type": "Description",
        "label": "Possible savings",
        "content": Hollowing_savings_md
    }],
    "imports": {
        "Hollowing_description_md": Hollowing_description_md,
        "Hollowing_savings_md": Hollowing_savings_md,
        "Hollowing_brain_slices_solid_hollow_png": Hollowing_brain_slices_solid_hollow_png,
        "Hollowing_feature_json": Hollowing_feature_json,
        "Hollowing_snapshot00_png": Hollowing_snapshot00_png
    }
}, {
    "active": false,
    "header": "Mesh Output",
    "cover": MeshOutput_Mesh_Output_png,
    "hidden": false,
    "published": false,
    "path": "/app/src/data/features/MeshOutput",
    "name": "MeshOutput",
    "elements": [{
        "type": "Description",
        "label": "Description",
        "content": MeshOutput_description_md
    }],
    "imports": {
        "MeshOutput_description_md": MeshOutput_description_md,
        "MeshOutput_Mesh_Output_png": MeshOutput_Mesh_Output_png,
        "MeshOutput_feature_json": MeshOutput_feature_json
    }
}, {
    "active": false,
    "header": "Highpoly meshes with high resolution textures",
    "cover": MillionsOfTriangles_Dragon_WaxColor_jpg,
    "hidden": false,
    "path": "/app/src/data/features/MillionsOfTriangles",
    "name": "MillionsOfTriangles",
    "elements": [{
        "type": "Description",
        "label": "Description",
        "content": MillionsOfTriangles_description_md
    }, {
        "type": "Description",
        "label": "Benchmark",
        "content": MillionsOfTriangles_benchmark_md
    }, {
        "type": "ImageList",
        "images": [{
            "src": MillionsOfTriangles_lucy01_png,
            "title": "Lucy: 3D render.",
            "alt": "A rendering of the Stanford St. Lucy model.",
            "width": 31.7,
            "height": 20.96
        }, {
            "src": MillionsOfTriangles_lucy02_png,
            "title": "Lucy: Closeup",
            "alt": "A closeup of the St. Lucy model, showing the finely tessellated mesh.",
            "width": 31.7,
            "height": 20.96
        }, {
            "src": MillionsOfTriangles_Lucy_front_crop_jpg,
            "title": "Lucy: 3D Print",
            "alt": "A 3D print of the Stanford St. Lucy model with different color and translucency values.",
            "width": 15.54,
            "height": 25.02
        }],
        "label": "Images - Lucy"
    }, {
        "type": "ImageList",
        "images": [{
            "src": MillionsOfTriangles_xyzrgb_dragon_00_png,
            "title": "Dragon: 3D Render",
            "alt": "A rendering of the Stanford Asian Dragon.",
            "width": 31.7,
            "height": 20.96
        }, {
            "src": MillionsOfTriangles_xyzrgb_dragon_01_png,
            "title": "Dragon: Closeup",
            "alt": "A closeup of the Asian Dragon model, showing the finely tessellated mesh.",
            "width": 31.7,
            "height": 20.96
        }, {
            "src": MillionsOfTriangles_Dragon_WaxColor_jpg,
            "title": "Dragon: 3D Model",
            "alt": "A 3D print of the Stanford Asian Dragon with varying colors and the translucency of wax.",
            "width": 43.48,
            "height": 23.36
        }],
        "label": "Images - Dragon"
    }, {
        "type": "ImageList",
        "images": [{
            "src": MillionsOfTriangles_victimes_00_jpg,
            "title": "Victimes: A textured 3D scan of the Communards' Wall.",
            "alt": "A rendering of a textured 3D scan of the Communards' Wall.",
            "width": 51.2,
            "height": 28.8
        }, {
            "src": MillionsOfTriangles_victimes_01_jpg,
            "title": "Victimes: Closeup.",
            "alt": "A close-up rendering of a textured 3D scan of the Communards' Wall.",
            "width": 51.2,
            "height": 28.8
        }, {
            "src": MillionsOfTriangles_Victimes_jpg,
            "title": "Victimes: A 3D print of the Communards' Wall.",
            "alt": "A rendering of a textured 3D scan of the Communards' Wall.",
            "width": 25.63,
            "height": 12.37
        }],
        "label": "Images - Victimes"
    }],
    "imports": {
        "MillionsOfTriangles_description_md": MillionsOfTriangles_description_md,
        "MillionsOfTriangles_benchmark_md": MillionsOfTriangles_benchmark_md,
        "MillionsOfTriangles_lucy01_png": MillionsOfTriangles_lucy01_png,
        "MillionsOfTriangles_lucy02_png": MillionsOfTriangles_lucy02_png,
        "MillionsOfTriangles_Lucy_front_crop_jpg": MillionsOfTriangles_Lucy_front_crop_jpg,
        "MillionsOfTriangles_xyzrgb_dragon_00_png": MillionsOfTriangles_xyzrgb_dragon_00_png,
        "MillionsOfTriangles_xyzrgb_dragon_01_png": MillionsOfTriangles_xyzrgb_dragon_01_png,
        "MillionsOfTriangles_Dragon_WaxColor_jpg": MillionsOfTriangles_Dragon_WaxColor_jpg,
        "MillionsOfTriangles_victimes_00_jpg": MillionsOfTriangles_victimes_00_jpg,
        "MillionsOfTriangles_victimes_01_jpg": MillionsOfTriangles_victimes_01_jpg,
        "MillionsOfTriangles_Victimes_jpg": MillionsOfTriangles_Victimes_jpg,
        "MillionsOfTriangles_Notes_txt": MillionsOfTriangles_Notes_txt,
        "MillionsOfTriangles_feature_json": MillionsOfTriangles_feature_json,
        "MillionsOfTriangles_victimes_02_jpg": MillionsOfTriangles_victimes_02_jpg
    }
}, {
    "active": false,
    "header": "Robust Voxelization",
    "cover": RobustVoxelization_armadillo_holes_points_png,
    "hidden": false,
    "path": "/app/src/data/features/RobustVoxelization",
    "name": "RobustVoxelization",
    "elements": [{
        "type": "Description",
        "label": "Description",
        "content": RobustVoxelization_description_md
    }, {
        "type": "ImageList",
        "images": [{
            "src": RobustVoxelization_armadillo_holes_input_slice192_dsdf_1_jpg,
            "title": "Cuttlefish's implicit voxelization produces plausible completion of corrupted surfaces.",
            "alt": "Cuttlefish's implicit voxelization.",
            "width": 23.57,
            "height": 37.67
        }, {
            "src": RobustVoxelization_armadillo_holes_points_png,
            "title": "Cuttlefish's implicit voxelization produces plausible completion of corrupted surfaces.",
            "alt": "The Stanford Armadillo corrupted with holes and with triangle connectivity (point cloud).",
            "width": 28.96,
            "height": 15
        }, {
            "src": RobustVoxelization_armadillo_holes_points_dsdf_png,
            "title": "Cuttlefish's implicit voxelization produces plausible completion of corrupted surfaces.",
            "alt": "Cuttlefish's implicit voxelization.",
            "width": 28.96,
            "height": 15
        }],
        "label": "Illustration"
    }, {
        "type": "ImageList",
        "images": [{
            "src": RobustVoxelization_Kopernicus_render_1_png,
            "title": "A rendering of an incomplete surface.",
            "alt": "A rendering of a scan of the Copernicus statue in Darmstadt, Germany.",
            "width": 9.15,
            "height": 14.26
        }, {
            "src": RobustVoxelization_Kopernicus_render_5_jpg,
            "title": "A rendering of an incomplete surface.",
            "alt": "A close-up of a scan of the Copernicus statue in Darmstadt, Germany.",
            "width": 29.98,
            "height": 18.18
        }, {
            "src": RobustVoxelization_Kopernicus_render_6_jpg,
            "title": "A rendering of an incomplete surface.",
            "alt": "A close-up of a scan of the Copernicus statue in Darmstadt, Germany.",
            "width": 9.86,
            "height": 14.4
        }, {
            "src": RobustVoxelization_Kopernicus_jpg,
            "title": "The printed result.",
            "alt": "A print of a scan of the Copernicus statue in Darmstadt, Germany.",
            "width": 19.99,
            "height": 28.03
        }],
        "label": "Print - Holes"
    }, {
        "type": "ImageList",
        "images": [{
            "src": RobustVoxelization_victimes_render_jpg,
            "title": "A rendering of an open surface.",
            "alt": "A rendering of a scan of the monument des victimes des revolutions in France.",
            "width": 29.68,
            "height": 12.69
        }, {
            "src": RobustVoxelization_Victimes_jpg,
            "title": "The printed result.",
            "alt": "A print of a scan of the monument des victimes des revolutions in France.",
            "width": 25.63,
            "height": 12.37
        }],
        "label": "Print - Open Surface"
    }, {
        "type": "ImageList",
        "images": [{
            "src": RobustVoxelization_porto_carmo_render_jpg,
            "title": "A rendering of a point cloud.",
            "alt": "A rendering of a scan of the Porto Carmo in Brazil.",
            "width": 9.21,
            "height": 13.08
        }, {
            "src": RobustVoxelization_PortoCarmo_jpg,
            "title": "The printed result.",
            "alt": "A print of a scan of the the Porto Carmo in Brazil.",
            "width": 17.99,
            "height": 25.99
        }],
        "label": "Print - Point Cloud"
    }],
    "imports": {
        "RobustVoxelization_description_md": RobustVoxelization_description_md,
        "RobustVoxelization_armadillo_holes_input_slice192_dsdf_1_jpg": RobustVoxelization_armadillo_holes_input_slice192_dsdf_1_jpg,
        "RobustVoxelization_armadillo_holes_points_png": RobustVoxelization_armadillo_holes_points_png,
        "RobustVoxelization_armadillo_holes_points_dsdf_png": RobustVoxelization_armadillo_holes_points_dsdf_png,
        "RobustVoxelization_Kopernicus_render_1_png": RobustVoxelization_Kopernicus_render_1_png,
        "RobustVoxelization_Kopernicus_render_5_jpg": RobustVoxelization_Kopernicus_render_5_jpg,
        "RobustVoxelization_Kopernicus_render_6_jpg": RobustVoxelization_Kopernicus_render_6_jpg,
        "RobustVoxelization_Kopernicus_jpg": RobustVoxelization_Kopernicus_jpg,
        "RobustVoxelization_victimes_render_jpg": RobustVoxelization_victimes_render_jpg,
        "RobustVoxelization_Victimes_jpg": RobustVoxelization_Victimes_jpg,
        "RobustVoxelization_porto_carmo_render_jpg": RobustVoxelization_porto_carmo_render_jpg,
        "RobustVoxelization_PortoCarmo_jpg": RobustVoxelization_PortoCarmo_jpg,
        "RobustVoxelization_feature_json": RobustVoxelization_feature_json
    }
}, {
    "active": false,
    "header": "Surface Dither",
    "cover": SurfaceDither_SurfaceDitheringfor3DPinting_ecaa6606_jpg,
    "hidden": false,
    "path": "/app/src/data/features/SurfaceDither",
    "name": "SurfaceDither",
    "elements": [{
        "type": "Description",
        "label": "Description",
        "content": SurfaceDither_description_md
    }, {
        "type": "Description",
        "label": "Usage",
        "content": SurfaceDither_usage_md
    }, {
        "type": "Description",
        "label": "Example - Cube",
        "content": SurfaceDither_cube_md
    }],
    "imports": {
        "SurfaceDither_description_md": SurfaceDither_description_md,
        "SurfaceDither_usage_md": SurfaceDither_usage_md,
        "SurfaceDither_cube_md": SurfaceDither_cube_md,
        "SurfaceDither_1_beerista_arm_close_hblue_JPG": SurfaceDither_1_beerista_arm_close_hblue_JPG,
        "SurfaceDither_1_beerista_arm_close_hred_JPG": SurfaceDither_1_beerista_arm_close_hred_JPG,
        "SurfaceDither_1_beerista_legs_hred_JPG": SurfaceDither_1_beerista_legs_hred_JPG,
        "SurfaceDither_1_spiritbase_hblue_JPG": SurfaceDither_1_spiritbase_hblue_JPG,
        "SurfaceDither_1_spiritsnow_hred_JPG": SurfaceDither_1_spiritsnow_hred_JPG,
        "SurfaceDither_4_beerista_arm_close_hblue_JPG": SurfaceDither_4_beerista_arm_close_hblue_JPG,
        "SurfaceDither_4_beerista_arm_close_hred_JPG": SurfaceDither_4_beerista_arm_close_hred_JPG,
        "SurfaceDither_4_beerista_legs_hred_JPG": SurfaceDither_4_beerista_legs_hred_JPG,
        "SurfaceDither_4_spiritbase_hblue_JPG": SurfaceDither_4_spiritbase_hblue_JPG,
        "SurfaceDither_4_spiritsnow_hred_JPG": SurfaceDither_4_spiritsnow_hred_JPG,
        "SurfaceDither_SurfaceDitheringfor3DPinting_ecaa6606_jpg": SurfaceDither_SurfaceDitheringfor3DPinting_ecaa6606_jpg,
        "SurfaceDither_beer1_JPG": SurfaceDither_beer1_JPG,
        "SurfaceDither_beer4_JPG": SurfaceDither_beer4_JPG,
        "SurfaceDither_cube1_JPG": SurfaceDither_cube1_JPG,
        "SurfaceDither_cube4_JPG": SurfaceDither_cube4_JPG,
        "SurfaceDither_feature_json": SurfaceDither_feature_json
    }
}, {
    "active": false,
    "header": "Texture Detail Preservation",
    "cover": TextureDetailPreservation_two_vases_JPG,
    "hidden": false,
    "path": "/app/src/data/features/TextureDetailPreservation",
    "name": "TextureDetailPreservation",
    "elements": [{
        "type": "Description",
        "label": "Description",
        "content": TextureDetailPreservation_description_md
    }, {
        "type": "Description",
        "label": "Usage",
        "content": TextureDetailPreservation_usage_md
    }],
    "imports": {
        "TextureDetailPreservation_description_md": TextureDetailPreservation_description_md,
        "TextureDetailPreservation_usage_md": TextureDetailPreservation_usage_md,
        "TextureDetailPreservation_feature_json": TextureDetailPreservation_feature_json,
        "TextureDetailPreservation_normal_JPG": TextureDetailPreservation_normal_JPG,
        "TextureDetailPreservation_sharp_JPG": TextureDetailPreservation_sharp_JPG,
        "TextureDetailPreservation_two_vases_JPG": TextureDetailPreservation_two_vases_JPG
    }
}, {
    "active": false,
    "header": "Voxel Input",
    "cover": VoxelInput_VoxelInput_png,
    "hidden": false,
    "path": "/app/src/data/features/VoxelInput",
    "name": "VoxelInput",
    "elements": [{
        "type": "Description",
        "label": "Description",
        "content": VoxelInput_description_md
    }],
    "imports": {
        "VoxelInput_description_md": VoxelInput_description_md,
        "VoxelInput_VoxelInput_png": VoxelInput_VoxelInput_png,
        "VoxelInput_feature_json": VoxelInput_feature_json
    }
}];