import { Box, Container, Divider, Typography } from "@material-ui/core";
import { React, useState, useCallback} from "react";
import Carousel, { Modal, ModalGateway } from "react-images";

import SubGallery from '../components/SubGallery';

import './Gallery.css'

import { gallery as GalleryPhotos } from "../data/gallery/GalleryPhotos";

export default function Gallery() {
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    const [activeGallery, setActiveGallery] = useState(0);

    const openLightbox = useCallback((event, { photo, index }) => {
        console.log(event);
        setCurrentImage(index);
        setViewerIsOpen(true);
      }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    console.log(GalleryPhotos[0].photos);

    return (
        <Container className="Gallery">        
            {/* <Box sx={{paddingTop: "2em", paddingBottom: "2em"}} ><Divider/></Box> */}
                <Typography variant="h2">Gallery</Typography>
            <Box sx={{paddingTop: "2em", paddingBottom: "2em"}}><Divider /></Box>
            { GalleryPhotos.map((gallery, index) => <SubGallery gallery={gallery} onClick={(a, b) => { setActiveGallery(index); openLightbox(a,b);} }></SubGallery>)}
            <ModalGateway>
                {viewerIsOpen ? (
                    <Modal onClose={closeLightbox}>
                        <Carousel 
                            currentIndex={currentImage}
                            views={GalleryPhotos[activeGallery].photos.map(x => ({
                                ...x,
                                srcset: x.srcset,
                                caption: x.title
                            }))}
                        />
                    </Modal>  
                ) : 0}
            </ModalGateway>
        </Container>
    )
}