import { Typography, Box } from "@material-ui/core"
import './AboutUs.css'

export default function AboutUs() {
    return (
        <Box className="aboutUsRoot">
            <Typography style={{ color: "white" }} variant="h6">About Us</Typography>
            <Typography style={{ color: "white" }} className="aboutUsText">
                Cuttlefish® is developed by the "3D Printing Technology" department at Fraunhofer IGD.
                We develop models, algorithms, and software for the perceptually optimal reproduction of 3D objects with annotated optical material properties by multi-material 3D printing.
            </Typography>
        </Box>
    )
}