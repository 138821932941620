import React from "react";

import './SplitscreenRenderer.css';

import * as THREE from "three";

import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { PLYLoader } from "three/examples/jsm/loaders/PLYLoader.js";

export default class SplitscreenRenderer extends React.Component {
    initial_state = {
        scene: null,
        scene2: null,
        camera: null,
        controls: null,
        renderer: null,
        mesh1: null,
        mesh2: null,
        sliderPos: 0,
    };

    eventPointerMove = null;
    eventPointerUp = null;

    constructor(props) {
        super(props);
        this.state = this.initial_state;
        this.canvas = React.createRef();
    }

    componentDidMount() {
        this.startRendering();
    }

    componentDidUpdate() {
        //this.startRendering()
        //if(this.props.index === this.props.value) this.startRendering();
        //else this.stopRendering();
    }

    stopRendering() {
        if(this.state.renderer !== null) this.setState(this.initial_state);
    }    

    onPointerDown(event) {

        if ( event.isPrimary === false ) return;

        this.eventPointerMove = this.onPointerMove.bind(this);
        this.eventPointerUp = this.onPointerUp.bind(this);

        window.addEventListener( 'pointermove', this.eventPointerMove );
        window.addEventListener( 'pointerup', this.eventPointerUp );
        const constrols = this.state.controls;
        constrols.enabled = false;
        this.setState({...this.state, controls: constrols});

    }

    onPointerMove( event ) {
        const canvasElement = this.canvas.current
        if ( event.isPrimary === false ) return;

        const sliderPos = Math.max( 0, Math.min( window.innerWidth, event.pageX ) ) - canvasElement.getBoundingClientRect().left;            
        const slider = document.querySelector( '.slider' );
        slider.style.left = sliderPos - ( slider.offsetWidth / 2 ) + "px";
        this.setState({ ...this.state, 'slider': slider,"sliderPos": sliderPos})
            
    }

    onPointerUp() {
        const controls = this.state.controls;
        controls.enabled = true;

        window.removeEventListener( 'pointermove', this.eventPointerMove );
        window.removeEventListener( 'pointerup', this.eventPointerUp );

        this.setState({...this.state, controls})

    }

    initSlider() {
        const slider = document.querySelector( '.slider' );
        if(!this.canvas.current) return;
        slider.style.touchAction = 'none'; // disable touch scroll
        slider.addEventListener( 'pointerdown', this.onPointerDown.bind(this) );
    }

    renderCanvas() {
        const canvasElement = this.canvas.current;
        if(!canvasElement) return;
        if(!this.state.renderer) return;
        if(!this.state.camera) return;
        if(!this.state.scene) return;

        if(!this.state.renderer) return;
        this.state.renderer.setScissor( 0, 0, this.state.sliderPos, canvasElement.offsetHeight );
        this.state.renderer.render( this.state.scene, this.state.camera );

        this.state.renderer.setScissor( this.state.sliderPos, 0, canvasElement.offsetWidth, canvasElement.offsetHeight );
        this.state.renderer.render( this.state.scene2, this.state.camera );
    }

    animate() {
        if(this.props.index !== this.props.value) {
            return;
        }
        requestAnimationFrame( this.animate.bind(this) );
        this.renderCanvas();
    }

    fitCameraToSelection( camera, controls, selection, fitOffset = 1.2 ) {
    
        const box = new THREE.Box3();
        
        for( const object of selection ) box.expandByObject( object );
        
        const size = box.getSize( new THREE.Vector3() );
        const center = box.getCenter( new THREE.Vector3() );
        
        const maxSize = Math.max( size.x, size.y, size.z );
        const fitHeightDistance = maxSize / ( 2 * Math.atan( Math.PI * camera.fov / 360 ) );
        const fitWidthDistance = fitHeightDistance / camera.aspect;
        const distance = fitOffset * Math.max( fitHeightDistance, fitWidthDistance );
        
        const direction = controls.target.clone()
            .sub( camera.position )
            .normalize()
            .multiplyScalar( distance );
        
        controls.maxDistance = distance * 10;
        controls.target.copy( center );
        
        camera.near = distance / 100;
        camera.far = distance * 100;
        camera.updateProjectionMatrix();
        
        camera.position.copy( controls.target ).sub(direction);
        
        controls.update();    
    }

    async loadMesh(meshName) {   
        return new Promise((resolve, reject) => {
            var loader = new PLYLoader();
            loader.load(meshName, async function(geometry) {
                var material = new THREE.MeshBasicMaterial( { vertexColors: THREE.VertexColors  } );
                var mesh = new THREE.Mesh( geometry, material );
                resolve(mesh);
            });
        })     
    }

    async startRendering() {        
        const canvasElement = this.canvas.current;
        if(!canvasElement) {
            return;
        }
        
        if(this.state.scene != null) return;

        const scene = new THREE.Scene();
        const scene2 = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera( 75, canvasElement.offsetWidth / canvasElement.offsetHeight, 0.1, 1000 );

        const renderer = new THREE.WebGLRenderer({canvas: canvasElement});
        renderer.setSize( canvasElement.offsetWidth, canvasElement.offsetHeight );
        const controls = new OrbitControls( camera, canvasElement );

        renderer.setScissorTest( true );

        let self = this;

        if(!this.state.slider) this.initSlider();
        
        if(self.state.mesh1 === null) {
            const mesh = await this.loadMesh(this.props.mesh1);    
            scene.add( mesh );
            self.setState({ ...self.state, mesh1: mesh});
        }

        if(this.state.mesh2 === null) {
            
            const mesh = await this.loadMesh(this.props.mesh2);    
            scene2.add( mesh );
            self.setState({ ...self.state, mesh2: mesh});
        }
        camera.position.z = 3;

        const sliderPos = canvasElement.offsetWidth * 0.5;
        this.setState({ ...this.initial_state, sliderPos, scene, scene2, camera, renderer, controls})
        this.animate();
    }

    render() {
        return (<div className="SplitscreenRenderer">
            <canvas ref={this.canvas}></canvas>
            <div className="slider"></div>
        </div>)
    }
}