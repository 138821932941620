import React from 'react';
import ReactDOM from 'react-dom';
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.min.js"
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@fontsource/roboto';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { combineReducers, createStore } from 'redux';
import { Provider } from 'react-redux';

const activePageReducer = (state = 0, action) => {
  if (action.type === 'SETTER') return action.data;
  else return state;
}

const isMobileReducer = (state = false, action) => {
  if (action.type === 'SETTER_ISMOBILE') return action.data;
  else return state;
}

const isXSmallReducer = (state = false, action) => {
  if (action.type === 'SETTER_ISXSMALL') return action.data;
  else return state;
}

const reducer = combineReducers({
  activePage: activePageReducer,
  isMobile: isMobileReducer,
  isXSmall: isXSmallReducer,
});

const store = createStore(reducer);


ReactDOM.render(
  <React.StrictMode>
    <React.Fragment anchor="main_anchor">
      <Provider store={store}>
        <App />
      </Provider>
    </React.Fragment>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
