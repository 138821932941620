export const publications = [{
    "imagePaper": "./publication_images/artificial_eyes.PNG",
    "title": "Automatic data-driven design and 3D printing of custom ocular prostheses",
    "authors": "Johann Reinhard, Philipp Urban, Stephen Bell, David Carpenter, Mandeep S. Sagoo",
    "venue": "Nature Communications",
    "submitDate": "2024-02-27",
    "file": "https://www.nature.com/articles/s41467-024-45345-5",
    "abstract": "The article presents the new digital workflow for the production of artificial eyes and also the methods and technology used in the data-driven design tool Cuttlefish::Eye developed at department at Fraunhofer IGD. It describes the processing of the image data, acquired using an optical coherence tomography (OCT) device with an integrated color camera, in order to produce a digital model of a prosthesis that matches the patient in shape and appearance. For this task several technologies from image processing and statistical shape modeling are utilized, including a color characterization and iterative fitting of the shape to the extracted surface information. The evaluation shows that the quality of the 3D-printed prostheses is very good, in particular the appearance of the iris. The benefit of our technology is the significantly reduced manual effort of the ocularist in the production process and the consistently high quality of the output.",
    "resources": [{
        "name": "Official Version",
        "url": "https://www.nature.com/articles/s41467-024-45345-5"
    }],
    "type": "Journal Articles"
}, {
    "imagePaper": "./publication_images/3D_Printing.PNG",
    "title": "Cuttlefish: Pushing the Limits of Graphical 3-D Printing",
    "authors": "Alan Brunton and Philipp Urban",
    "venue": "IEEE Computer Graphics and Applications 43.5 (2023): 114-121",
    "submitDate": "2023-09-14",
    "file": "https://ieeexplore.ieee.org/stamp/stamp.jsp?arnumber=10251712",
    "abstract": "This article presents Cuttlefish, a 3-D printer driver developed at the Fraunhofer Institute for Computer Graphics Research IGD. Cuttlefish maximizes the reproduction quality of shape and appearance in multimaterial 3-D printing systems. It controls various printing systems and material sets, including color, transparent, and flexible materials. The article provides an overview of Cuttlefish’s graphical 3-D printing pipeline and focuses on its unique features: Displaced signed distance fields for robust voxelization of objects not designed for 3-D printing, joint color and translucency reproduction, accurate color and translucency characterization, shape dithering to eliminate quantization-based staircase artifacts, and support for displacement maps and constructive solid geometry operations. The article concludes by showcasing applications of Cuttlefish in printing replacement faces for stop-motion animation, large-scale figurine production, and prosthetic eye printing.",
    "resources": [{
        "name": "Official Version",
        "url": "https://ieeexplore.ieee.org/stamp/stamp.jsp?arnumber=10251712"
    }],
    "type": "Journal Articles"
}, {
    "imagePaper": "./publication_images/Meso-Facets for Goniochromatic 3D Printing.jpg",
    "title": "Meso-Facets for Goniochromatic 3D Printing",
    "authors": "Lubna Abu Rmaileh and Alan Brunton",
    "venue": "ACM Transactions on Graphics (Proc. SIGGRAPH) Volume 42 Issue 4",
    "submitDate": "2023-05-10",
    "file": "https://owncloud.fraunhofer.de/index.php/s/NpeO1FCh75MdIni",
    "abstract": "Goniochromatic materials and objects appear to have different colors depending on viewing direction. This occurs in nature, such as in wood or minerals, and in human-made objects such as metal and effect pigments. In this paper, we propose algorithms to control multi-material 3D printers to produce goniochromatic effects on arbitrary surfaces by procedurally augmenting the input surface with meso-facets, which allow distinct colors to be assigned to different viewing directions of the input surface while introducing minimal changes to that surface. Previous works apply only to 2D or 2.5D surfaces, require multiple fabrication technologies, or make considerable changes to the input surface and require special post-processing, whereas our approach requires a single fabrication technology and no special post-processing. Our framework is general, allowing different generating functions for both the shape and color of the facets. Working with implicit representations allows us to generate geometric features at the limit of device resolution without tessellation. We evaluate our approach for performance, showing negligible overhead compared to baseline color 3D print processing, and for goniochromatic quality.",
    "resources": [{
        "name": "Supplemental Material",
        "url": "https://owncloud.fraunhofer.de/index.php/s/1VtPdXSmwszdqxR"
    }, {
        "name": "Official Version",
        "url": "https://dl.acm.org/doi/10.1145/3592137"
    }],
    "type": "Journal Articles"
}, {
    "imagePaper": "./publication_images/Multi-printer learning framework for efficient optical printer characterization.png",
    "title": "Multi-printer learning framework for efficient optical printer characterization",
    "authors": "Danwu Chen, Philipp Urban",
    "venue": "Optics Express Vol. 31, Issue 8, pp. 13486-13502 (2023)",
    "submitDate": "2023-04-07",
    "file": "https://opg.optica.org/oe/fulltext.cfm?uri=oe-31-8-13486&id=528952",
    "abstract": "A high prediction accuracy of optical printer models is a prerequisite for accurately reproducing visual attributes (color, gloss, translucency) in multimaterial 3D printing. Recently, deep-learning-based models have been proposed, requiring only a moderate number of printed and measured training samples to reach a very high prediction accuracy. In this paper, we present a multi-printer deep learning (MPDL) framework that further improves data efficiency utilizing supporting data from other printers. Experiments on eight multi-material 3D printers demonstrate that the proposed framework can significantly reduce the number of training samples thus the overall printing and measurement efforts. This makes it economically feasible to frequently characterize 3D printers to achieve a high optical reproduction accuracy consistent across different printers and over time, which is crucial for color- and translucency-critical applications.",
    "resources": [{
        "name": "Official Version",
        "url": "https://opg.optica.org/oe/fulltext.cfm?uri=oe-31-8-13486&id=528952"
    }, {
        "name": "Supplemental Material",
        "url": "https://opticapublishing.figshare.com/articles/journal_contribution/Supplementary_document_for_Multi-printer_learning_framework_for_efficient_optical_printer_characterization_-_6293868_pdf/22301320"
    }],
    "type": "Journal Articles"
}, {
    "imagePaper": "./publication_images/Perceptually Optimizing Color Look-up Tables.gif",
    "title": "Perceptually Optimizing Color Look-up Tables",
    "authors": "Johann Reinhard, Philipp Urban",
    "venue": "IEEE Transactions on Image Processing, Vol. 32, pp. 403-414 (2023)",
    "submitDate": "2022-12-15",
    "file": "https://ieeexplore.ieee.org/document/9989430",
    "abstract": "The quality of ICC profiles with embedded look-up tables (LUTs) depends on multiple factors: 1. the accuracy of the optical printer model, 2. the exploitation of the available gamut combined with the quality of the gamut mapping approach encoded in the B2A-LUTs (backwards LUTs) and 3. the tonal smoothness as well color accuracy of the backwards LUTs. It can be shown that optimizing the smoothness of the LUTs comes at the expense of color accuracy and requires gamut reduction because of internal tonal edges. We present a method to optimize backwards LUTs of existing ICC profiles w.r.t accuracy, smoothness, gamut exploitation and mapping, which can be extended beyond color, e.g. to joint color and translucency backward LUTs. The approach is based on a perceptual difference metric that is used to optimize the LUT’s tonal smoothness constrained to preserve both the accuracy of and the relationship between colors.",
    "resources": [{
        "name": "Official Version",
        "url": "https://ieeexplore.ieee.org/document/9989430"
    }],
    "type": "Journal Articles"
}, {
    "imagePaper": "./publication_images/SurfaceDitheringfor3DPinting.ecaa6606.jpg",
    "title": "Shape dithering for 3D printing",
    "authors": "Mostafa Morsy Abdelkader Morsy, Alan Brunton, and Philipp Urban",
    "venue": "ACM Transactions on Graphics (Proc. SIGGRAPH) Volume 41 Issue 4",
    "submitDate": "2022-07-22",
    "file": "https://owncloud.fraunhofer.de/index.php/s/1FN3qlHi3rqBjdm",
    "abstract": "We present an efficient, purely geometric, algorithmic, and parameter free approach to improve surface quality and accuracy in voxel-controlled 3D printing by counteracting quantization artifacts. Such artifacts arise due to the discrete voxel sampling of the continuous shape used to control the 3D printer, and are characterized by low-frequency geometric patterns on surfaces of any orientation. They are visually disturbing, particularly on small prints or smooth surfaces, and adversely affect the fatigue behavior of printed parts. We use implicit shape dithering, displacing the part's signed distance field with a high-frequent signal whose amplitude is adapted to the (anisotropic) print resolution. We expand the reverse generalized Fourier slice theorem by shear transforms, which we leverage to optimize a 3D blue-noise mask to generate the anisotropic dither signal. As a point process it is efficient and does not adversely affect 3D halftoning. We evaluate our approach for efficiency, geometric accuracy and show its advantages over the state of the art.",
    "resources": [{
        "name": "Supplemental Material",
        "url": "https://owncloud.fraunhofer.de/index.php/s/1ypaGDHyMR4FDxx"
    }, {
        "name": "Official Version",
        "url": "https://dl.acm.org/doi/10.1145/3528223.3530129"
    }],
    "type": "Journal Articles"
}, {
    "imagePaper": "./publication_images/Inducing robustness and plausibility in deep learning optical 3D printer models.jfif",
    "title": "Inducing robustness and plausibility in deep learning optical 3D printer models",
    "authors": "Danwu Chen and Philipp Urban",
    "venue": "Optics Express Vol. 30, Issue 11, pp. 18119-18133 (2022)",
    "submitDate": "2022-05-10",
    "file": "https://opg.optica.org/oe/fulltext.cfm?uri=oe-30-11-18119&id=472730",
    "abstract": "Optical 3D printer models characterize multimaterial 3D printers by predicting optical or visual quantities from material arrangements or tonal values. Their accuracy and robustness to noisy training data are crucial for 3D printed appearance reproduction. In our recent paper [Opt. Express 29, 615 (2021)], we have proposed a pure deep learning (PDL) optical model and a training strategy achieving high accuracy with a moderate number of training samples. Since the PDL model is essentially a black-box without considering any physical grounding, it is sensitive to outliers or noise of the training data and tends to create physically-implausible tonal-to-optical relationships. In this paper, we propose a methodology to narrow down the degrees-of-freedom of deep-learning based optical printer models by inducing physically plausible constraints and smoothness. Our methodology does not need any additional printed samples for training. We use this approach to introduce the robust plausible deep learning (RPDL) optical printer model enhancing robustness to erroneous and noisy training data as well as physical plausibility of the PDL model for selected tonal-to-optical monotonicity relationships. Our experiments on four state-of-the-art multimaterial 3D printers show that the RPDL model not only almost always corrects implausible tonal-to-optical relationships, but also ensures significantly smoother predictions, without sacrificing accuracy. On small training data, it even outperforms the PDL model in accuracy by up to 8% indicating a better generalization ability.",
    "resources": [{
        "name": "Official Version",
        "url": "https://opg.optica.org/oe/fulltext.cfm?uri=oe-30-11-18119&id=472730"
    }, {
        "name": "Supplemental Material",
        "url": "https://opticapublishing.figshare.com/articles/journal_contribution/Supplementary_document_for_Inducing_Robustness_and_Plausibility_in_Deep_Learning_Optical_3D_Printer_Models_-_5818352_pdf/19658796"
    }],
    "type": "Journal Articles"
}, {
    "imagePaper": "./publication_images/Displaced Signed Distance Fields for Additive Manufacturing.JPG",
    "title": "Displaced Signed Distance Fields for Additive Manufacturing",
    "authors": "Alan Brunton and Lubna Abu Rmaileh",
    "venue": "ACM Transactions on Graphics (Proc. SIGGRAPH) Volume 40 Issue 4",
    "submitDate": "2021-08-09",
    "file": "https://owncloud.fraunhofer.de/index.php/s/QyPUy0MOQaoUX5W",
    "abstract": "We propose displaced signed distance fields, an implicit shape representation to accurately, efficiently and robustly 3D-print finely detailed and smoothly curved surfaces at native device resolution. As the resolution and accuracy of 3D printers increase, accurate reproduction of such surfaces becomes increasingly realizable from a hardware perspective. However, representing such surfaces with polygonal meshes requires high polygon counts, resulting in excessive storage, transmission and processing costs. These costs increase with print size, and can become exorbitant for large prints. Our implicit formulation simultaneously allows the augmentation of low-polygon meshes with compact meso-scale topographic information, such as displacement maps, and the realization of curved polygons, while leveraging efficient,streaming-compatible, discrete voxel-wise algorithms. Critical for this is careful treatment of the input primitives, their voxel approximation and the displacement to the true surface. We further propose a robust sign estimation to allow for incomplete, non-manifold input, whether human-made for on-screen rendering or directly out of a scanning pipeline. Our framework is efficient both in terms of time and space. The running time is independent of the number of input polygons, the amount of displacement, and is constant per voxel. The storage costs grow sub-linearly with the number of voxels, making our approach suitable for large prints. We evaluate our approach for efficiency and robustness, and show its advantages over standard techniques.",
    "resources": [{
        "name": "Supplemental Material",
        "url": "https://owncloud.fraunhofer.de/index.php/s/czizFG6jQn3jsDP"
    }, {
        "name": "Official Version",
        "url": "https://doi.org/10.1145/3450626.3459827"
    }],
    "type": "Journal Articles"
}, {
    "imagePaper": "./publication_images/Deep learning models for optically characterizing 3D printers.jfif",
    "title": "Deep learning models for optically characterizing 3D printers",
    "authors": "Danwu Chen, Philipp Urban",
    "venue": "Optics Express Vol. 29, Issue 2, pp. 615-631",
    "submitDate": "2021-01-01",
    "file": "https://www.osapublishing.org/oe/viewmedia.cfm?uri=oe-29-2-615&seq=0",
    "abstract": "Multi-material 3D printers are able to create material arrangements possessing various optical properties. To reproduce these properties, an optical printer model that accurately predicts optical properties from the printer’s control values (tonals) is crucial. We present two deep learning-based models and training strategies for optically characterizing 3D printers that achieve both high accuracy with a moderate number of required training samples. The first one is a Pure Deep Learning (PDL) model that is essentially a black-box without any physical ground and the second one is a Deep-Learning-Linearized Cellular Neugebauer (DLLCN) model that uses deep-learning to multidimensionally linearize the tonal-value-space of a cellular Neugebauer model. We test the models on two six-material polyjetting 3D printers to predict both reflectances and translucency. Results show that both models can achieve accuracies sufficient for most applications with much fewer training prints compared to a regular cellular Neugebauer model.",
    "resources": [{
        "name": "Official Version",
        "url": "https://www.osapublishing.org/oe/fulltext.cfm?uri=oe-29-2-615&id=445683"
    }, {
        "name": "Code",
        "url": "https://osapublishing.figshare.com/articles/software/code_DeepLeaningCalibrationPaper_zip/13095743"
    }, {
        "name": "Supplemental Material",
        "url": "https://osapublishing.figshare.com/articles/journal_contribution/Supplementary_document_for_Deep_Learning_Models_for_Optically_Characterizing_3D_Printers_-_4888307_pdf/13365434"
    }],
    "type": "Journal Articles"
}, {
    "imagePaper": "./publication_images/Redefining A in RGBA Towards a Standard for Graphical 3D Printing.JPG",
    "title": "Redefining A in RGBA: Towards a Standard for Graphical 3D Printing",
    "authors": "Philipp Urban, Tejas Madan Tanksale, Alan Brunton, Bui Minh Vu, Shigeki Nakauchi",
    "venue": "ACM Transactions on Graphics, Volume 38, Issue 3, Article 21",
    "submitDate": "2019-05-10",
    "file": "https://owncloud.fraunhofer.de/index.php/s/S0nC1bLKgh8uS56",
    "abstract": "Advances in multimaterial 3D printing have the potential to reproduce various visual appearance attributes of an object in addition to its shape. Since many existing 3D file formats encode color and translucency by RGBA textures mapped to 3D shapes, RGBA information is particularly important for practical applications. In contrast to color (encoded by RGB), which is specified by the object’s reflectance, selected viewing conditions and a standard observer, translucency (encoded by A) is neither linked to any measurable physical nor perceptual quantity. Thus, reproducing translucency encoded by A is open for interpretation. In this paper, we propose a rigorous definition for A suitable for use in graphical 3D printing, which is independent of the 3D printing hardware and software, and which links both optical material properties and perceptual uniformity for human observers. By deriving our definition from the absorption and scattering coefficients of virtual homogeneous reference materials with an isotropic phase function, we achieve two important properties. First, a simple adjustment of A is possible, which preserves the translucency appearance if an object is re-scaled for printing. Second, determining the value of A for a real (potentially non-homogeneous) material, can be achieved by minimizing a distance function between light transport measurements of this material and simulated measurements of the reference materials. Such measurements can be conducted by commercial spectrophotometers used in graphic arts.",
    "resources": [{
        "name": "Appendix",
        "url": "https://owncloud.fraunhofer.de/index.php/s/R9r7Fk0hBUiiKN4"
    }, {
        "name": "Source code and data",
        "url": "https://owncloud.fraunhofer.de/index.php/s/HbYgTx51uGxCS8d"
    }, {
        "name": "Official Version",
        "url": "https://doi.org/10.1145/3319910"
    }],
    "type": "Journal Articles"
}, {
    "imagePaper": "./publication_images/3D Printing Spatially Varying Color and Translucency.JPG",
    "title": "3D Printing Spatially Varying Color and Translucency",
    "authors": "Alan Brunton, Can Ates Arikan, Tejas Madan Tanksale, Philipp Urban",
    "venue": "ACM Transactions on Graphics (Proc. SIGGRAPH) Volume 37, Issue 4",
    "submitDate": "2018-08-16",
    "file": "https://owncloud.fraunhofer.de/index.php/s/csdCsWEw8wu6PbZ",
    "abstract": "We present an efficient and scalable pipeline for fabricating full-colored objects with spatially-varying translucency from practical and accessible input data via multi-material 3D printing. Observing that the costs associated with BSSRDF measurement and processing are high, the range of 3D printable BSSRDFs are severely limited, and that the human visual system relies only on simple high-level cues to perceive translucency, we propose a method based on reproducing perceptual translucency cues. The input to our pipeline is an RGBA signal defined on the surface of an object, making our approach accessible and practical for designers. We propose a framework for extending standard color management and profiling to combined color and translucency management using a gamut correspondence strategy we call opaque relative processing. We present an efficient streaming method to compute voxel-level material arrangements, achieving both realistic reproduction of measured translucent materials and artistic effects involving multiple fully or partially transparent geometries.",
    "resources": [{
        "name": "Appendix",
        "url": "https://owncloud.fraunhofer.de/index.php/s/jC6Q4MTFeUHk7MI"
    }, {
        "name": "Input Data",
        "url": "https://owncloud.fraunhofer.de/index.php/s/hDwd2L6Yey2ObuI"
    }, {
        "name": "Official Version",
        "url": "https://doi.org/10.1145/3197517.3201349"
    }, {
        "name": "Slides",
        "url": "https://owncloud.fraunhofer.de/index.php/s/Fph6sAMclUdcaqb"
    }],
    "type": "Journal Articles"
}, {
    "imagePaper": "./publication_images/Continuous Property Gradation for Multi-material 3D-printed Objects.JPG",
    "title": "Continuous Property Gradation for Multi-material 3D-printed Objects",
    "authors": "C. Altenhofen, T. H. Luu, T. Grasser, M. Dennstädt, J. S. Mueller-Roemer, D. Weber, A. Stork",
    "venue": "Solid Freeform Fabrication 2018",
    "submitDate": "2018-08-01",
    "file": "https://owncloud.fraunhofer.de/index.php/s/ksiL2UnUilInEsK",
    "abstract": "",
    "resources": [],
    "type": "Conference Papers"
}, {
    "imagePaper": "./publication_images/A Distributed 3D Print Driver.JPG",
    "title": "A Distributed 3D Print Driver",
    "authors": "Suman Bidarahalli",
    "venue": "Master's thesis, TU Darmstadt",
    "submitDate": "2017-02-01",
    "file": "https://owncloud.fraunhofer.de/index.php/s/a8e6jCoM99ilioP",
    "abstract": "Determining material arrangements to control high-resolution multi-material 3D printers for reproducing shape and visual attributes of a 3D model (e.g. spatially-varying color, translucency and gloss) requires large computational effort. Today's resolution and print tray sizes allow prints with more than 1+e12 voxels each filled with one of the available printing materials (today up to 7 materials can be combined in a single print). Cuttlefish, a 3D printing pipeline, processes the input in a serial fashion leading to increased computation time for higher number of models. Distributed computing is one way of achieving better performance for large computations. Through this master thesis, we have developed a distributed version of the cuttlefish printer driver in which the computational task is distributed amongst multiple nodes in the cluster and the resulting partial output is merged to generate the full slices. The architecture supports streaming, which is required to rapidly start the print before the full computation is finished, as cuttlefish processes the input in small parts and generates chunk-wise output. Finally, the comparison of the performance achieved by the distributed vs the non-distributed cuttlefish version is established to get a better understanding of the advantages and the challenges of distributed computing.",
    "resources": [],
    "type": "Theses"
}, {
    "imagePaper": "./publication_images/Visual perception of 3D printed translucent objects.JPG",
    "title": "Visual perception of 3D printed translucent objects",
    "authors": "Bui Minh Vu, Philipp Urban, Tejas Madan Tanksale, Shigeki Nakauchi",
    "venue": "IS&T Color and Imaging Conference (CIC) 2016",
    "submitDate": "2016-11-01",
    "file": "https://owncloud.fraunhofer.de/index.php/s/TOd3duzcf2t3qIj",
    "abstract": "",
    "resources": [],
    "type": "Conference Papers"
}, {
    "imagePaper": "./publication_images/GCode Erzeugung aus Voxeldaten für FDM-Drucker.JPG",
    "title": "GCode Erzeugung aus Voxeldaten für FDM-Drucker",
    "authors": "Marco Dennstädt",
    "venue": "Master Thesis, Hochschule Darmstadt",
    "submitDate": "2016-02-10",
    "file": "https://owncloud.fraunhofer.de/index.php/s/VJ4SYjQYfhQJDTF",
    "abstract": "Diese Masterarbeit beschäftigt sich mit dem Generieren von Werkzeugpfaden für den 3D-Druck. Als Eingabedaten dient eine schichtweise Voxeldarstellung der 3D-Modelle. Pro Schicht müssen nun konturparallele Pfade und Pfade für Stütz- und Füllmaterial berechnet werden. Hier kommt in der Arbeit zunächst ein Algorithmus zur Konturverfolgung zum Einsatz; der “Radial-Sweep“-Algorithmus, allerdings scheitert dieser bei sich verschränkenden Konturen. So können; aufgrund von zu druckenden Löchern im Modell; mehrdeutige Pfade entstehen, die der Algorithmus ignorieren würde, da er lediglich eine Oberfläche einer Komponente und nicht womögliche innere Oberflächen betrachtet. Auch für Sackgassen muss am Ende der Sackgasse ein neuer Punkt zum weiterdrucken gefunden werden. In dieser Arbeit werden hierfür zunächst eindeutige und mehrdeutige Pfade voneinander getrennt. Für die mehrdeutigen Pfade wird ein Graph erstellt, welcher zunächst auch unwichtige Informationen enthält. Dieser wird letztendlich so reduziert, dass nur die wichtigsten mehrdeutigen Pfade enthalten sind. Dieser Graph und die zuvor gesammelten eindeutigen Pfadinformationen werden dann zur Traversierung benutzt. Weiterhinwerden in dieser Arbeit Möglichkeiten zum Drucken mit unterschiedlichen Auflösungen besprochen, um den Detailgrad des Drucks auf Voxelbasis zu erhöhen. Die Ergebnisse dieser Arbeit sind Ausdrucke, welche Vergleichbar mit polygonbasierten Pfaderzeugungsmethoden sind.",
    "resources": [],
    "type": "Theses"
}, {
    "imagePaper": "./publication_images/Trichromatic Reflectance Capture Using a Tunable Light Source Setup, Characterization and Reflectance Estimation.JPG",
    "title": "Trichromatic Reflectance Capture Using a Tunable Light Source: Setup, Characterization and Reflectance Estimation",
    "authors": "Tejas Madan Tanksale, Philipp Urban",
    "venue": "IS&T International Symposium on Electronic Imaging 2016 | Measuring, Modeling, and Reproducing Material Appearance 2016",
    "submitDate": "2016-02-01",
    "file": "https://owncloud.fraunhofer.de/index.php/s/jFFAOydYEK33Abf",
    "abstract": "",
    "resources": [],
    "type": "Conference Papers"
}, {
    "imagePaper": "./publication_images/Pushing the Limits of 3D Color Printing Error Diffusion with Translucent Materials.JPG",
    "title": "Pushing the Limits of 3D Color Printing: Error Diffusion with Translucent Materials",
    "authors": "Alan Brunton, Can Ates Arikan, Philipp Urban",
    "venue": "ACM Transactions on Graphics (TOG) Volume 35 Issue 1",
    "submitDate": "2015-12-27",
    "file": "https://owncloud.fraunhofer.de/index.php/s/qW5cJIdZSBq8JGK",
    "abstract": "Accurate color reproduction is important in many applications of 3D printing, from design prototypes to 3D color copies or portraits. Although full color is available via other technologies, multi-jet printers have greater potential for graphical 3D printing, in terms of reproducing complex appearance properties. However, to date these printers cannot produce full color, and doing so poses substantial technical challenges, from the shear amount of data to the translucency of the available color materials. In this article, we propose an error diffusion halftoning approach to achieve full color with multi-jet printers, which operates on multiple isosurfaces or layers within the object. We propose a novel traversal algorithm for voxel surfaces, which allows the transfer of existing error diffusion algorithms from 2D printing. The resulting prints faithfully reproduce colors, color gradients and fine-scale details.",
    "resources": [{
        "name": "Supplemental",
        "url": "https://owncloud.fraunhofer.de/index.php/s/S3rpi1lyDg202cS"
    }, {
        "name": "Video",
        "url": "https://owncloud.fraunhofer.de/index.php/s/NRwiEFrq0Hf3vNa"
    }, {
        "name": "Official Version",
        "url": "http://dx.doi.org/10.1145/2832905"
    }, {
        "name": "Slides",
        "url": "https://owncloud.fraunhofer.de/index.php/s/elwbpUKthrnCEoR"
    }],
    "type": "Journal Articles"
}, {
    "imagePaper": "./publication_images/Development of a Spectral Acquisition System using a Tunable Light Source and a Trichromatic Camera.JPG",
    "title": "Development of a Spectral Acquisition System using a Tunable Light Source and a Trichromatic Camera",
    "authors": "Tejas Madan Tanksale",
    "venue": "Master's thesis, TU Darmstadt",
    "submitDate": "2015-07-22",
    "file": "https://owncloud.fraunhofer.de/index.php/s/gFPqceqObgdu67F",
    "abstract": "Colours perceived by humans are influenced by a large number of factors. The same object may look different under different lighting conditions. This is also true for images captured by a camera sensor. In addition to this, each measuring device has its own capturing properties. For example, the RGB intensities captured by different cameras are different for the same object in the same lighting conditions. To avoid these variations in the observed colour, it is necessary to know the ground truth of the colour data of the object, which is given by its spectral reflectance. In this thesis, we devise a method for estimating the spectral reflectances at a fast speed using a tunable monochromatic light source and a trichromatic camera. The estimation is a two-step process: first we need to determine the camera sensitivities, secondly, we use the estimated sensitivities to calculate the reflectances. For both experiments we use the same setup which allows us to use software application programming interfaces (APIs) to obtain reflectances for a large number of targets at an extreme speed and accuracy. For the evaluation of our method, we employ a spectroradiometer which can directly measure the spectra of the targets.",
    "resources": [],
    "type": "Theses"
}, {
    "imagePaper": "./publication_images/Color-managed 3D printing with highly translucent printing materials.JPG",
    "title": "Color-managed 3D printing with highly translucent printing materials",
    "authors": "Can Ates Arikan, Alan Brunton, Tejas Madan Tanksale, Philipp Urban",
    "venue": "Measuring, Modeling, and Reproducing Material Appearance 2015",
    "submitDate": "2015-03-13",
    "file": "https://owncloud.fraunhofer.de/index.php/s/PqqwynWVyXWwrv4",
    "abstract": "",
    "resources": [],
    "type": "Conference Papers"
}]