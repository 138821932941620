import { Box, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import './FeatureDescription.css'

import rehypeRaw from 'rehype-raw'

// import FeatureData from '../data/features/Features'

const Path = require('path');

function resolveResourcePath(path) {
    return Path.normalize(path).replace(/[ -.]/g, '_');
}

export default function FeatureDescription({content, imports, featureName}) {
    const [postText, setPostText] = useState(null);

    useEffect(() => {
        async function fetchContent () {
            const res = await fetch(content);
            setPostText(await res.text());
        }
        fetchContent()
    }, [content])
    return(
        <Box sx={{m:3}}>
            <Typography className="FeatureDescription" align="justify">
                <ReactMarkdown  rehypePlugins={[rehypeRaw]}
                components={{
                    img({ src, alt, ...props }) {
                        return <img className="post-image" alt={alt} src={imports[featureName + '_' +  resolveResourcePath(src)]} {...props} />;
                    }
                }}>{ postText }</ReactMarkdown>
            </Typography>
        </Box>
    )
}