import Features from './pages/Features';
import Home from './pages/Home';
import Gallery from './pages/Gallery';
import { News } from './pages/News';
import SiteNotice from './pages/SiteNotice';
// import AboutUs from './components/AboutUs';
import DataProtectionInformation from './pages/DataProtectionInformation';
import LicenseManager from './pages/LicenseManager';
import DesignGuidelines from './pages/DesignGuidelines';
import Services from './pages/Services';
import Publications from './pages/Publications';
import ContactForm from './pages/ContactForm';
import CompanionSoftware from './pages/CompanionSoftware';
import ReleaseHistory from './pages/ReleaseHistory';
import CustomerSupport from './pages/CustomerSupport';

const menuItems = [
    { key:0, label: "Home", target: "/", component: Home, inMenu: true, inDropdown: false},
    { key:1, label: "Features", target: "/features", component: Features, inMenu: true, inDropdown: true},
    { key:2, label: "Gallery", target: "/gallery", component: Gallery, inMenu: true, inDropdown: true},
    { label: "OnLine Documentation", link: "https://3dt.pages.fraunhofer.de/cuttlefish/", inMenu: true, inDropdown: true},
    { key:3, label: "News", target: "/news", component: News, inMenu: true, inDropdown: false},
    { key:4, label: "Design Guidelines", target: '/design-guidelines', component: DesignGuidelines, inMenu: true, inDropdown: true },
    { key:5, label: "Services", target: '/services', component: Services, inMenu: true, inDropdown: false },
    { key:6, label: "Publications", target: '/publications', component: Publications, inMenu: true, inDropdown: false },
    { key:7, label: "Companion Software", target: '/companion-software', component: CompanionSoftware, inMenu: true, inDropdown: false },
    { key:8, label: "Release History", target: '/release-history', component: ReleaseHistory, inMenu: true, inDropdown: true },
    { key:9,  label: "Contact", target: '/contact', component: ContactForm, inMenu: true, inDropdown: false },
    { key:10, label: "Imprint", target: '/imprint', component: SiteNotice, inMenu: false, inDropdown: false },
    { key:11, label: "Data Protection Policy", target: '/data-protection-information', component: DataProtectionInformation, inMenu: false, inDropdown: false },
    { key:12, label: "License Manager", target: '/license-manager', component: LicenseManager, inMenu: false, inDropdown: false },
    { key:13,  label: "Customer Support", target: '/customersupport', component: CustomerSupport, inMenu: true, inDropdown: false },
];
export default menuItems;
