import { Typography } from '@material-ui/core'
import { default as ReactPhotoGallery } from "react-photo-gallery" ;
import './SubGallery.css'

export default function SubGallery(props) {
    const { gallery, onClick } = props;
    return (<div>
        <Typography variant="h3">{gallery.name}</Typography>
        <ReactPhotoGallery photos={gallery.photos} onClick={onClick}/>
    </div>)
}