import { Box, Container, Typography } from "@material-ui/core";
import React from "react";
import HomeCarousel from "../components/HomeCarousel";
import './Home.css';

export default class Home extends React.Component {

    render() {
        return (
            <Box>
                {/* <Box className="titleWrapper" style={{ backgroundImage: `url(${TilteImage})` }}><div className="headlineWrapper"><Typography className="mainTitle" variant="h1">Cuttlefish®</Typography></div></Box> */}
                <HomeCarousel/>
                <Box >
                    <Container p={3}>
                        <Box sx={{ float: "left", width: { lg: "60%" } }}>
                            <Typography variant="h4" component="h2">Get the most out of your 3D printer</Typography>
                            <Typography>Cuttlefish® is a unique driver for controlling diverse 3D printers allowing high-fidelity reproduction of an object’s appearance in addition to its shape and more.</Typography>
                            <Box p={2}>
                                <Typography className="sellingPointHeadline" variant="h6">Quality</Typography>
                                <Typography>Pushing the limits of accuracy and realism in 3D printing*</Typography>
                            </Box>
                            <Box p={2}>
                                <Typography className="sellingPointHeadline" variant="h6">Adaptability</Typography>
                                <Typography>Can be configured to various printer specs and capabilities</Typography>
                            </Box>
                            <Box p={2}>
                                <Typography className="sellingPointHeadline" variant="h6">Reliability</Typography>
                                <span>Stable software producing consistent results</span>
                            </Box>
                            <Typography variant="h4" component="h2">Why Cuttlefish?</Typography>
                            <ul>
                                <Typography component="li">Configurable and adjustable to customer’s needs by user friendly browser-based interface</Typography>
                                <Typography component="li">Automatically process complex input models: millions of triangles, multiple texture maps, overlapping and embedded models with different color and translucency textures</Typography>
                                <Typography component="li">Allows remote printing</Typography>
                                <Typography component="li">Starts printing in seconds using streaming architecture</Typography>
                                <Typography component="li">Supports ICC color management</Typography>
                                <Typography component="li">Accurate softproofing</Typography>
                                <Typography component="li">Allows batch processing</Typography>
                                <Typography component="li">Extendible by plugins</Typography>
                            </ul>
                            <Box style={{ "paddingTop": "2em" }}>
                                <Typography variant="h4" component="h2">Streaming Architecture</Typography>
                                <Typography>Cuttlefish® enables users to start printing in seconds after the computation started. This is realized by processing the output for the build tray portion by portion from bottom to top.</Typography>
                            </Box>
                            <Box style={{ "paddingTop": "2em" }}>
                                <Typography variant="h4" component="h2">Component-Pipeline Architecture</Typography>
                                <Typography>Users and developers are able to choose from a set of components the ones they need to drive their specific printer.</Typography>
                            </Box>
                            <Box style={{ "paddingTop": "2em" }}>
                                <Typography variant="h4" component="h2">Plugin Compatibility</Typography>
                                <Typography>Adding new components and therefore new features to Cuttlefish® is easy. We provide a C++ SDK to develop new components that can be loaded into Cuttlefish® dynamically.</Typography>
                            </Box>
                        </Box>
                        <Box width="30rem" sx={{ float: "right", width: { lg: "23em" }, paddingTop: "5em" }}>
                            <Typography><b>*"We have never seen such accurate and detailed color before in a plastic 3D print"</b></Typography>
                            <Typography>-- Brian McLean, LAIKA Animation Studios</Typography>
                        </Box>
                        <Box width="30rem" sx={{ float: "right", width: { lg: "23em" }, paddingTop: "5em" }}>
                            <Typography><b>More about us</b></Typography>
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/ps4m5WDqrbM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </Box>
                    </Container>
                </Box>
            </Box>
        )
    }
}