import './News.css';
import ReactMarkdown from 'react-markdown';

import { useEffect, useState } from 'react';
import { Box, Container, Divider, Link as MUILink, Typography } from '@material-ui/core';
import { posts, resources } from '../data/news/.tables.js';
import { Switch, Route, useRouteMatch, Link } from 'react-router-dom';
import { Modal, ModalGateway, default as Carousel } from 'react-images';

import rehypeRaw from 'rehype-raw'

const Path = require('path');

function resolveResourcePath(root, path) {
    let fullPath = root + '/' + path;
    const newPath = Path.normalize(fullPath);
    return newPath;
}

function stripHeader(text) {
    const lines = text.replace(/\r\n?/g, '\n').split('\n')
    const headerEnd = lines.indexOf('---', 1);
    return lines.slice(headerEnd + 1).join('\n');
}

export function Post({ newsId }) {
    const post = posts[newsId];
    const [postText, setPostText] = useState(null);

    useEffect(() => (async () => {
        setPostText(null);
        const res = await fetch(post.source);
        setPostText(stripHeader(await res.text()));
    })(), [post.source]);

    const [openImg, setOpenImg] = useState(null);

    return (
    <>
        <p><MUILink component={Link} to={'/news'}>Back to all news</MUILink></p>
        <Typography>
            <ModalGateway>
                {
                    openImg !== null ? (
                        <Modal onClose={() => setOpenImg(null)}>
                            <Carousel
                                currentIndex={0}
                                views={[{
                                    src: openImg.source,
                                    alt: openImg.alt,
                                    caption: openImg.alt,
                                }]}
                            />
                        </Modal>
                    ) : null
                }
            </ModalGateway>
            {postText !== null ?
                <ReactMarkdown rehypePlugins={[rehypeRaw]}
                    components={{
                        img({ src, alt, ...props }) {
                            return <img className="post-image" alt={alt}
                                onClick={() => setOpenImg({ source: resources[resolveResourcePath(post.root, src)], alt: alt })}
                                src={resources[resolveResourcePath(post.root, src)]} {...props} />;
                        }
                    }}
                >{postText}</ReactMarkdown> : "Loading ..."}
        </Typography>
    </>
    )
}

function PostOverview({ newsId }) {
    const post = posts[newsId];
    return (
        <Link className="News_link" to={`/news/${newsId}`}>
            <div class="card mb-4  News_cover  shadow-sm" xstyle="max-width: 540px;">
                <div class="row g-0">
                    <div class="xcol-md-12 ">
                        <div class="card-body ">
                            <Typography variant="h4">
                                {post.title}
                            </Typography>
                            <Typography variant="h3" sx={{ fontSize: 14 }} xcolor="text.secondary" gutterBottom></Typography>
                            
                            <Typography sx={{ mb: 1.5 }} xcolor="text.secondary">
                                {post.day}.{post.month}.{post.year}
                            </Typography>
                        <ReactMarkdown>{post.preview}</ReactMarkdown>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
     );
}


function Catalogue() {
    return <Container>
        {Object.keys(posts).map(p => <PostOverview key={p} newsId={p} />)}
    </Container>;
}

export function News() {
    let { url } = useRouteMatch();

    return <Container>            
            {/* <Box sx={{paddingTop: "2em", paddingBottom: "2em"}}><Divider/></Box> */}
                <Typography variant="h2">News</Typography>
            <Box sx={{paddingTop: "2em", paddingBottom: "2em"}}><Divider /></Box>
        <Switch>
            {Object.keys(posts).map(p => <Route key={p} exact path={`${url}/${p}`}><Post newsId={p} /></Route>)}
            <Route exact path={url}><Catalogue /></Route>
            <Route path="*">404 News not found</Route>
        </Switch>
    </Container>;
}

