import React from 'react';
import './FeatureCardPage.css';
class FeatureCardContentType {
    static IMAGE_LIST = "ImageList";
    static SPLITSCREEN_RENDERER = "SplitScreenRenderer";
    static DESCRIPTION = "Description";
}

class FeatureCardPage extends React.Component {
    initial_state = {
    };

    eventPointerMove = null;
    eventPointerUp = null;

    constructor(props) {
        super(props);
        this.state = this.initial_state;
    }
    
    getClassName() {
        return "feature-information" + ((this.props.value === this.props.index) ? "" : " hidden");
    }

    render() {
        const { children, value, index, ...other } = this.props;
        return (<div
            role="tabpanel"
            id={`page-${index}`}
            className={this.getClassName()}
            p={3}
            {...other}>
                {index === value ? children : null}
            </div>
        )
    }
};

export { FeatureCardPage, FeatureCardContentType }