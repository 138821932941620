import { Box, Container, Divider, Typography } from "@material-ui/core";
import guidelines_pdf from '../data/design_guidelines/2020-06-09_Design-Guidelines-Overlapping_web.pdf'

export default function Services() {
    return (
        <Container>
            <Typography variant="h2">Services</Typography>
            <Box sx={{paddingTop: "2em", paddingBottom: "2em"}}><Divider /></Box>
            <Box sx={{paddingBottom: "2em"}}>
                <Typography variant="h6">We help you adapt Cuttlefish to your 3D printer and exploit its full capabilities.</Typography>
            </Box>
            <div class="card text-white bg-dark mb-3" style={{"max-width": "30rem"}}>
                {/* <div class="card-header">Our service</div> */}
                <div class="card-body">
                    <h5 class="card-title">Our service</h5>
                    <ul>
                        <li>Connecting Cuttlefish to your printer's firmware</li>
                        <li>Conducting optical measurements</li>
                        <li>Creating custom ICC and material profiles</li>
                        <li>Maximizing the color gamut of your 3D printer</li>
                    </ul>
                </div>
            </div>

            <div class="card text-white bg-dark mb-3" style={{"max-width": "30rem"}}>
                {/* <div class="card-header">Our service</div> */}
                <div class="card-body">
                    <h5 class="card-title">Our Competences</h5>
                     <ul>
                        <li>Optical Measurements</li>
                        <li>Psychophysics</li>
                        <li>Color Science</li>
                        <li>Geometry & Imaging</li>
                        <li>Developing Algorithms</li>
                        <li>Developing Professional Software</li>
                    </ul>
                </div>
            </div>
            <Box  sx={{paddingTop: "2em"}}>
              <Typography>We have compiled a document, available <a href={guidelines_pdf} rel="noreferrer" target="_blank">here</a>, outlining the design guidelines for creating joint color and translucency 3D prints from RGBA-textured 3D models with Cuttlefish.</Typography>
            </Box>
        </Container>
    )
}