import { Box, Button, Container, Divider, FormControl, FormGroup, FormHelperText, Grid, Input, InputLabel, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

import axios from 'axios'
import anatomy_cut from '../images/anatomy_cut.jpg';
import './ContactForm.css'

export default function ContactForm() {
    const { register, handleSubmit, formState } = useForm()

    const [blocked, setBlocked] = useState(false);
    const [message, setMessage] = useState({ isError: false, message: ' ' });

    const onSubmit = async (data) => {
        if (!data) return false;
        try {
            setMessage({ isError: false, message: " " });
            
            // Block send button for 10 seconds
            // If the user will not respect that in some way, they will be blocked in the backend for one hour (and have to send an email using their provider)
            setBlocked(true)
            setTimeout(() => setBlocked(false), 10000);
            await axios.post('/submit_contact_form/', data);
            setMessage({ isError: false, message: "Mail has been sent." });
        } catch (e) {
            setMessage({ isError: true, message: e.toString() });
        }
        return false
    }

    const fields = [
        { name: "First Name", type: "text", id: "first_name", xs: 6, fieldProps: { required: true } },
        { name: "Last Name",  type: "text", id: "last_name", xs: 6, fieldProps: { required: true } },
        { name: "Company",  type: "text", id: "company", xs: 6, fieldProps: { required: true } },
        { name: "Email",  type: "email", id: "email", xs: 6, fieldProps: { required: true } },
        { name: "Subject", type: "text",  id: "subject", xs: 6, fieldProps: { required: true } },
        { name: "Message", type: "text",  id: "message", xs: 12, rows: 10, fieldProps: { required: true } }
    ]

    return (
        <Container>
            {/* <Box sx={{ paddingTop: "2em", paddingBottom: "2em" }}><Divider /></Box> */}
            <Typography variant="h2">Contact</Typography>
            <Box sx={{ paddingTop: "2em", paddingBottom: "2em" }}><Divider /></Box>
            <div class="card mb-3">
                <div class="row g-0">
                    <div class="col-md-4   d-none d-md-flex justify-content-center">
                        <div className='h-100 w-100 image_parent  d-flex align-items-end justify-content-center'>
                            <img src={anatomy_cut} class="img-fluid  contact_img_cover" alt="contact card"/>
                        </div>
                        <div className='text_caption text-white'>
                            <Typography>For questions related to licensing or something else related to Cuttlefish please use the contact form or send a mail to <a href="mailto:web.cuttlefish@igd.fraunhofer.de">web.cuttlefish@igd.fraunhofer.de</a></Typography>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="card-body">
                            <Typography className='d-md-none'>For questions related to licensing or something else related to Cuttlefish please use the contact form or send a mail to <a href="mailto:web.cuttlefish@igd.fraunhofer.de">web.cuttlefish@igd.fraunhofer.de</a></Typography>
                            <Box sx={{ paddingTop: "2em", paddingBottom: "2em" }}>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <FormHelperText error={message.isError}>{message.message}</FormHelperText>
                                    <FormGroup sx={{ width: '25ch' }}>
                                        <Grid container spacing={2}>
                                            {fields.map((field) => {
                                                const color = formState.errors[field.id] ? 'secondary' : 'primary';
                                                return (
                                                    <Grid item xs={field.xs} key={field.id}>
                                                        <FormControl style={{ width: "100%" }} error={!!formState.errors[field.id]} color={color} >
                                                            <InputLabel htmlFor={field.id}>{field.name}</InputLabel>
                                                            {
                                                                field.rows ?
                                                                    <Input type={field.type}  id={field.id} noValidate multiline rows={field.rows} {...register(field.id, field.fieldProps)}></Input> : 
                                                                    <Input type={field.type} id={field.id} {...register(field.id, field.fieldProps)}></Input>
                                                            }
                                                        </FormControl>
                                                    </Grid>
                                                );
                                            }
                                            )}
                                        </Grid>
                                        <Grid item style={{ paddingTop: "2em" }}>
                                            <Button
                                                type="submit"
                                                variant="outlined"
                                                color="inherit"
                                                disabled={blocked}
                                            >Send</Button>
                                        </Grid>
                                    </FormGroup>
                                </form>
                            </Box>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    )
}