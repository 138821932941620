import { Box, Container, Divider, Typography } from "@material-ui/core";
import { Link } from 'react-router-dom';
import {useDispatch} from 'react-redux';


export default function CompanionSoftware() {
    const dispatch = useDispatch();
    
    function handleOnclick(){
         dispatch({ type: "SETTER", data: 3});
    }
    
    return (
        <Container>
            {/* <Box sx={{paddingTop: "2em", paddingBottom: "2em"}}><Divider/></Box> */}
                <Typography variant="h2">Companion Software</Typography>
            <Box sx={{paddingTop: "2em", paddingBottom: "2em"}}><Divider /></Box>
            <Typography className="" variant="h4">Cuttlefish::Eye</Typography>
            <img alt="" src="./images/CuttlefishEye.jpg" height="150"/>
            <Box sx={{paddingTop: "1em"}}></Box>
            <Typography align="justify">
                Cuttlefish::Eye is a data-driven software that automatically creates a digital cosmetic artificial eye model for the clinical use in patients.
                Our software utilises a 3D scan of the eye socket and a colour-calibrated photo of the healthy eye, captured with an optical coherence tomography (OCT) device by an occularist.
                The output is optimized for printing the prosthesis with our Cuttlefish® 3D printer driver software on a multicolor, multimaterial 3D printer.
            </Typography >
            <Typography className="mt-3">Check the news about an application of Cuttlefish::Eye:</Typography >
            <Link onClick={handleOnclick} to="/news/FraunhoferTechnologyRevolutionises3DPrintingOfProstheticEyes">
                <Typography>Fraunhofer technology revolutionises 3D printing of prosthetic eyes</Typography>
            </Link>

            <Typography className="mt-3">Check our new paper: </Typography >
            <Link onClick={handleOnclick} to="/news/OurPaperDescribingCuttlefishEyeAndThe3DprintedOcularProsthesesWasPublishedInNatureCommunications">
                <Typography> "Automatic data-driven design and 3D printing of custom ocular prostheses"</Typography>
            </Link>

            <div className="mt-3"></div>
            <img alt="" src="./images/3d-druck_augenprothese_2.jpg" height="300" className="pe-2"/>
            <img alt="" src="./images/3d-druck_augenprothese.jpg" height="300" className="pe-2"/>
            <img alt="" src="./images/moorfields_patient.jpg" height="300"/>
            <br/>
            <Typography>
                © Images by courtesy of Professor Mandeep Sagoo, consultant ophthalmologist at Moorfields Eye Hospital and professor of ophthalmology at the NIHR Biomedical Research Centre at Moorfields Eye Hospital and UCL Institute of Ophthalmology
            </Typography>

            <Typography className="fw-bold mt-3 xborder xborder-danger">
            Note on the distribution of ocular prostheses
            </Typography>

            <Typography> 
            The Fraunhofer Institute for Computer Graphics Research IGD has developed the software for 3D printing eye prostheses and licenses this technology to the company Ocupeye Limited, which manufactures the prostheses. Fraunhofer IGD does not manufacture the prostheses and does not treat patients - please contact https://ocupeye.com/ for more information.
            </Typography>


            <Box sx={{paddingTop: "2em", paddingBottom: "0em"}}><Divider /></Box>




            <Box sx={{paddingTop: "2em"}}></Box>
            <Typography variant="h4">Cuttlefish::Proof</Typography>
            <Box sx={{paddingTop: "1em"}}></Box>
            <Typography align="justify">Cuttlefish::Proof is a color accurate softproof of 3D prints made using our Cuttlefish® 3D printer driver. Cuttlefish::Proof can be tuned to match the printing process and post processing of the prints to give the best possible match. Cuttlefish::Proof is currently in the beta stage, contact us for more details.</Typography>    
        </Container>
    )
}