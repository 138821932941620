import cuttlefish2024R2 from "./2024R2.md";
import cuttlefish2024R1 from "./2024R1.md";
import cuttlefish2023R2 from "./2023R2.md";
import cuttlefish2023R1 from "./2023R1.md";
import cuttlefish2022R2 from "./2022R2.md";
import cuttlefish2022R1 from "./2022R1.md";
import cuttlefish2021R2 from "./2021R2.md";
import cuttlefish2021R1 from "./2021R1.md";
import cuttlefish2020R2 from "./2020R2.md";
import cuttlefish2020R1 from "./2020R1.md";


const releaseNotes = 
[
    {
        "title": "Cuttlefish® 2024.R2",
        "date": "2024-11-15",
        description: cuttlefish2024R2,
    },
    {
        "title": "Cuttlefish® 2024.R1",
        "date": "2024-05-08",
        description: cuttlefish2024R1,
    },
    {
        "title": "Cuttlefish® 2023.R2",
        "date": "2023-10-31",
        description: cuttlefish2023R2,
    },
    {
        "title": "Cuttlefish® 2023.R1",
        "date": "2023-05-22",
        description: cuttlefish2023R1,
    },
    {
        "title": "Cuttlefish® 2022.R2",
        "date": "2022-11-08",
        description: cuttlefish2022R2,
    },
    {
        "title": "Cuttlefish® 2022.R1",
        "date": "2022-05-02",
        description: cuttlefish2022R1,
    },
    {
        "title": "Cuttlefish® 2021.R2",
        "date": "2021-09",
        description: cuttlefish2021R2,
    },
    {
        "title": "Cuttlefish® 2021.03",
        "date": "",
        description: cuttlefish2021R1,
    },
    {
        "title": "Cuttlefish® 2020.09",
        "date": "",
        description: cuttlefish2020R2,
    },
    {
        "title": "Cuttlefish® 2020.03",
        "date": "",
        description: cuttlefish2020R1,
    },


]

export default releaseNotes;