import React from "react";
import look_up_table from '../images/perceptually_optimizing_color_lookup_tables.png';
import multiPrinter_learning_framework from'../images/multiPrinter_learning_framework.png';
import logo_white from '../images/logo_white.png';
import Goniochromatic_3D_Printing from '../images/Goniochromatic_3D_Printing.jpg';
import printing from '../images/3D_Printing.PNG';
import eyes from '../images/artificial_eyes1.JPG';
import formnext from '../images/formnext2024.jpg';
import "./HomeCarousel.css"; 
import { Link } from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';

function HomeCarousel(){
    const dispatch = useDispatch();
    const isMobile = useSelector(state => state.isMobile);
    
    function handleOnclick(){
         dispatch({ type: "SETTER", data: 3});
    }

    const slides = [
        {
            ariaLabel: "Slide 1",
            id: "slide1",
            image: Goniochromatic_3D_Printing,
            slideText: "Our paper on goniochromatic 3D printing was accepted to SIGGRAPH 2023",
            link: "/news/OurPaperOnGoniochromatic3DPrintingWasAcceptedToSIGGRAPH2023",
        },
        {
            ariaLabel: "Slide 2",
            id: "slide2",
            active: true,
            image: formnext,
            slideText: "We'll be at formnext in Frankfurt!",
            link: "/news/CuttlefishAtFormNext2024",
        },
        {
            ariaLabel: "Slide 3",
            id: "slide3",
            image: logo_white,
            slideText: "Latest release Cuttlefish® 2024.R2",
            link: "/news/Cuttlefish2024R2Released",
        },
        {
            ariaLabel: "Slide 4",
            id: "slide4",
            image: printing,
            slideText: "Our paper on Cuttlefish: Pushing the Limits of Graphical 3-D Printing was accepted to IEEE CG&A",
            link: "/news/OurPaperOnPushingTheLimitsOfGraphical3DPrintingWasAcceptedToIEEE",    
        },
        {
            ariaLabel: "Slide 5",
            id: "slide5",
            image: look_up_table,
            slideText: "Our paper on Optimizing Color Look-up-tables is published in IEEE TIP",
            link: "/news/WeReleasedANewIEEEArticle",
            
        },
        {
            ariaLabel: "Slide 6",
            id: "slide6",
            image: multiPrinter_learning_framework,
            slideText: "Our paper on Multi-Printer Deep Learning framework was published in Optics Express",
            link: "/news/OurPaperOnMultiPrinterDeepLearningFrameworkWasPublishedInOpticsExpress",
            
        },
        {
            ariaLabel: "Slide 7",
            id: "slide7",
            image: eyes,
            slideText: "Our paper describing Cuttlefish::Eye and the 3D-printed ocular prostheses was published in Nature Communications",
            link: "/news/OurPaperDescribingCuttlefishEyeAndThe3DprintedOcularProsthesesWasPublishedInNatureCommunications",
        
        }
    ]


    return(
        <div id="carouselExampleIndicators" class="carousel slide d-flex justify-content-center" data-bs-ride="carousel">
                <div class={isMobile? "d-none":"carousel-indicators m-5"} id="indicator">
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"   class="active" aria-current="true"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="5" aria-label="Slide 6"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="6" aria-label="Slide 7"></button>
                </div>
                <div class="carousel-inner" id="slides">
                    {
                        slides.map((slide)=>(
                            <div class={`carousel-item ${slide.active === true? "active":""}`} xdata-bs-interval="1000">
                            <div className="w-100 h-100 d-flex justify-content-center"  id={slide.id}>
                                <img src={slide.image} class="d-block w-100" alt={JSON.stringify(slide.image)}/>
                            </div>
                            <div className="pdiv d-flex bg-light border justify-content-center">
                                {slide.slideText.length < 83?
                                    <h2 className="text-black">
                                        {slide.slideText}
                                    </h2>
                                    :
                                    <h4 className="text-black">
                                        {slide.slideText}
                                    </h4>
                                }
    
                            </div>
                            <Link  onClick={handleOnclick} className={isMobile? "moreInfo_smallScreen":"moreInfo" } to={slide.link}>
                                <h5 className="m-0"  id="text">More Info</h5>
                            </Link>
                        </div>
                        ))
                    }
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
        </div>

    )

}

export default HomeCarousel;