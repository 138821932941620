import Doctor_print_10cm_crop_jpg from './Mimaki 3DUJ-553/Doctor_print_10cm_crop.jpg';
import Footballs_JPG from './Mimaki 3DUJ-553/Footballs.JPG';
import Mimaki_anatomy_back_png from './Mimaki 3DUJ-553/Mimaki_anatomy_back.png';
import Mimaki_anatomy_small_png from './Mimaki 3DUJ-553/Mimaki_anatomy_small.png';
import Teapot_Cuttlefish_JPG from './Mimaki 3DUJ-553/Teapot_Cuttlefish.JPG';
import Teapot_dishCloth_displacement_JPG from './Mimaki 3DUJ-553/Teapot_dishCloth_displacement.JPG';
import Victimes_jpg from './Mimaki 3DUJ-553/Victimes.jpg';
import Wall_3000x2000_jpg from './Mimaki 3DUJ-553/Wall_3000x2000.jpg';
import Anatomy47_shoulders_up_png from './Stratasys J750/Anatomy47_shoulders_up.png';
import Dragon_WaxColor_jpg from './Stratasys J750/Dragon_WaxColor.jpg';
import HessenLion_FhG_crop810_jpg from './Stratasys J750/HessenLion_FhG_crop810.jpg';
import IGD_3D_01d_zoom_png from './Stratasys J750/IGD 3D 01d_zoom.png';
import IGD_3D_02_png from './Stratasys J750/IGD 3D 02.png';
import IGD_3D_03a_png from './Stratasys J750/IGD 3D 03a.png';
import IGD_3D_04b_png from './Stratasys J750/IGD 3D 04b.png';
import IGD_3D_06_png from './Stratasys J750/IGD 3D 06.png';
import IMG_1385_dunkel_png from './Stratasys J750/IMG_1385_dunkel.png';
import Lucy_front_crop_jpg from './Stratasys J750/Lucy_front_crop.jpg';
import eye_png from './Stratasys J750/eye.png';

export const gallery = [
	{
		name: "Mimaki 3DUJ-553",
		photos: [
			{
				src: Doctor_print_10cm_crop_jpg,
				width: 1528,
				height: 2763
			},
			{
				src: Footballs_JPG,
				width: 5760,
				height: 3840
			},
			{
				src: Mimaki_anatomy_back_png,
				width: 808,
				height: 1272
			},
			{
				src: Mimaki_anatomy_small_png,
				width: 961,
				height: 1608
			},
			{
				src: Teapot_Cuttlefish_JPG,
				width: 5760,
				height: 3840
			},
			{
				src: Teapot_dishCloth_displacement_JPG,
				width: 5760,
				height: 3840
			},
			{
				src: Victimes_jpg,
				width: 2563,
				height: 1237
			},
			{
				src: Wall_3000x2000_jpg,
				width: 3000,
				height: 2000
			}
		]
	},
	{
		name: "Stratasys J750",
		photos: [
			{
				src: Anatomy47_shoulders_up_png,
				width: 1728,
				height: 1152
			},
			{
				src: Dragon_WaxColor_jpg,
				width: 4348,
				height: 2336
			},
			{
				src: HessenLion_FhG_crop810_jpg,
				width: 829,
				height: 810
			},
			{
				src: IGD_3D_01d_zoom_png,
				width: 1000,
				height: 667
			},
			{
				src: IGD_3D_02_png,
				width: 1000,
				height: 681
			},
			{
				src: IGD_3D_03a_png,
				width: 1000,
				height: 678
			},
			{
				src: IGD_3D_04b_png,
				width: 1000,
				height: 668
			},
			{
				src: IGD_3D_06_png,
				width: 1000,
				height: 668
			},
			{
				src: IMG_1385_dunkel_png,
				width: 1000,
				height: 653
			},
			{
				src: Lucy_front_crop_jpg,
				width: 1554,
				height: 2502
			},
			{
				src: eye_png,
				width: 3024,
				height: 4032
			}
		]
	}
];