import { Box, Container, Divider, Typography } from "@material-ui/core";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useState} from 'react';
import axios from 'axios';

export default function CustomerSupport() {

    const problems = ["Unexpected Print Results", "Cuttlefish Gives an Error", "Licensing Problems", "How do I ... ?", "Other"]
    const [information, setInformation] = useState({
        "firstName": "",
        "lastName": "",
        "company": "",
        "email": "",
        "selectionOfProblems": "",
        "description": "",
        "printerType": "",
        "cuttlefishVersion": "",
        "GUI": false,
        "CLI": false,
        "inputData": "",
        "outputData": "",
        "picturesOfPrint": "",
        "errorMessage": "",
        "inputDataThatTrggerError": "",
        "screenshots":"",
        "documentationRead": false,
        "questionOfDocumentation": "",
        "otherQuestions": ""
    })

    // console.log("info", information);

    const [toShow, setToShow] = useState([]);

    const handleInfo = (event) => {
              
        if(event.target.name==="firstName"){
            setInformation((oldState) => {
                return{...oldState, "firstName": event.target.value}
            })
        }
        else if (event.target.name==="lastName"){
            setInformation((oldState) => {
                return{...oldState, "lastName": event.target.value}
            })
        }
        else if (event.target.name==="company"){
            setInformation((oldState) => {
                return{...oldState, "company": event.target.value}
            })
        }
        else if (event.target.name==="email"){
            setInformation((oldState) => {
                return{...oldState, "email": event.target.value}
            })
        }
        else if (event.target.name==="selectionOfProblems"){
            setInformation((oldState) => {
                return{...oldState, "selectionOfProblems": event.target.value}
            })
            if (event.target.value === "Unexpected Print Results") {
                setToShow(["description", "printerType", "version", "GUI_CLI", "additionalInfo"])
            }
            else if (event.target.value === "Cuttlefish Gives an Error") {
                setToShow(["description", "printerType","version", "GUI_CLI", "errorMessage", "additionalInfo2"])
            }
            else if (event.target.value === "Licensing Problems") {
                setToShow(["description", "version", "GUI_CLI", "errorMessage" ])
            }
            else if (event.target.value === "How do I ... ?") {
                setToShow(["documentation"])
            }
            else if (event.target.value === "Other") {
                setToShow(["otherQuestion"])
            }
            else{
                setToShow([])
            }
        }
        else if (event.target.name==="description"){
            setInformation((oldState) => {
                return{...oldState, "description": event.target.value}
            })
        }
        else if (event.target.name==="printerType"){
            setInformation((oldState) => {
                return{...oldState, "printerType": event.target.value}
            })
        }
        else if (event.target.name==="cuttlefishVersion"){
            setInformation((oldState) => {
                return{...oldState, "cuttlefishVersion": event.target.value}
            })
        }
        else if (event.target.name==="GUI"){
            setInformation((oldState) => {
                return{...oldState, "GUI": event.target.checked}
            })
        }
        else if (event.target.name==="CLI"){
            setInformation((oldState) => {
                return{...oldState, "CLI": event.target.checked}
            })
        }
        else if (event.target.name==="inputData"){
            setInformation((oldState) => {
                return{...oldState, "inputData": event.target.value}
            })
        }
        else if (event.target.name==="outputData"){
            setInformation((oldState) => {
                return{...oldState, "outputData": event.target.value}
            })
        }
        else if (event.target.name==="picturesOfPrint"){
            setInformation((oldState) => {
                return{...oldState, "picturesOfPrint": event.target.value}
            })
        }
        else if (event.target.name==="errorMessage"){
            setInformation((oldState) => {
                return{...oldState, "errorMessage": event.target.value}
            })
        }
        else if (event.target.name==="inputDataThatTrggerError"){
            setInformation((oldState) => {
                return{...oldState, "inputDataThatTrggerError": event.target.value}
            })
        }
        else if (event.target.name==="screenshots"){
            setInformation((oldState) => {
                return{...oldState, "screenshots": event.target.value}
            })
        }
        else if (event.target.name==="documentationRead"){
            setInformation((oldState) => {
                return{...oldState, "documentationRead": event.target.checked}
            })
        }
        else if (event.target.name==="questionOfDocumentation"){
            setInformation((oldState) => {
                return{...oldState, "questionOfDocumentation": event.target.value}
            })
        }
        else if (event.target.name==="otherQuestions"){
            setInformation((oldState) => {
                return{...oldState, "otherQuestions": event.target.value}
            })
        } 

    }


    const [blocked, setBlocked] = useState(false);
    const onSubmit = async (data) => {
        if (!data) return false;
        try {

            // Block send button for 10 seconds
            // If the user will not respect that in some way, they will be blocked in the backend for one hour (and have to send an email using their provider)
            setBlocked(true)
            setTimeout(() => setBlocked(false), 10000);

            if(information.email === ""){
                alert("Please provide your email.")
            }else{
                let copy = {...data};

                copy.type = "customer_support";
                
                await axios.post('/submit_contact_form/', 
                                JSON.stringify(copy));//contact form server can not parse the object correctly
                alert("Questions have been sent.")
            }

        } catch (e) {
            alert("Something wrong")
        }
        return false;
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        onSubmit(information);
        
    }


    
    return (
        <Container>
            <Typography variant="h2">Customer Support</Typography>
            <Box sx={{paddingTop: "2em", paddingBottom: "2em"}}><Divider /></Box>

        <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="firstname">
                <Form.Label className="">First Name</Form.Label>
                <Form.Control name="firstName" value={information.firstName} onChange={handleInfo} type="text" placeholder="First Name" />
            </Form.Group>

            <Form.Group className="mb-3" controlId="lastname">
                <Form.Label>Last Name</Form.Label>
                <Form.Control type="text" placeholder="Last Name" name="lastName" value={information.lastName} onChange={handleInfo} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="company">
                <Form.Label>Company</Form.Label>
                <Form.Control type="text" placeholder="Company" name="company"  value={information.company} onChange={handleInfo}/>
            </Form.Group>

            <Form.Group className="mb-3" controlId="email">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email" placeholder="Enter email" name="email" value={information.email} onChange={handleInfo}/>
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>How can we help you?</Form.Label>
                <Form.Select name="selectionOfProblems"  value={information.selectionOfProblems} onChange={handleInfo}>
                <option>please select</option>
                    {problems.map((p,index) => 
                        <option key={index}>{p}</option>
                    )}
                </Form.Select>
            </Form.Group>

            {toShow.includes("description")?
                <Form.Group className="mb-3" controlId="descriptionOfProblem">
                    <Form.Label>Description of the problem</Form.Label>
                    <Form.Control as="textarea" rows={3} name="description" value={information.description} onChange={handleInfo}/>
                </Form.Group>
            :undefined}

            {toShow.includes("printerType")?
            <Form.Group className="mb-3" controlId="printerType">
                <Form.Label>Printer Type</Form.Label>
                <Form.Control type="text" placeholder="Printer Type" name="printerType" value={information.printerType} onChange={handleInfo}/>
            </Form.Group>
            :undefined}

            {toShow.includes("version")?
            <Form.Group className="mb-3" controlId="version">
                <Form.Label>Cuttlefish Version</Form.Label>
                <Form.Control type="text" placeholder="Cuttlefish Version" name="cuttlefishVersion" value={information.cuttlefishVersion} onChange={handleInfo}/>
            </Form.Group>
            :undefined}

            {toShow.includes("GUI_CLI")?
            <Form.Group className="mb-3" >
                <Form.Label>I am using</Form.Label>
                <Form.Check aria-label="option 1" label="the GUI" name="GUI" checked={information.GUI} onChange={handleInfo} />
                <Form.Check aria-label="option 2" label="the command line interface (CLI)" name="CLI" checked={information.CLI} onChange={handleInfo}/>
            </Form.Group>
            :undefined}


            {toShow.includes("additionalInfo")?
                <Form.Group className="mb-3">
                    <Form.Label>Additional information (critical to help us resolve the issue!)</Form.Label>

                    <Form.Control className="my-2" type="text" placeholder="please provide a link to download the input data" name="inputData" value={information.inputData} onChange={handleInfo}/>

                    <Form.Control className="my-2" type="text" placeholder="please provide a link to download the output of Cuttlefish (gcvf/m2c)" name="outputData" value={information.outputData} onChange={handleInfo}/>

                    <Form.Control className="my-2" type="text" placeholder="please provide a link to pictures of the print if you have" name="picturesOfPrint" value={information.picturesOfPrint} onChange={handleInfo}/>
                </Form.Group>
            :undefined}

            {toShow.includes("errorMessage")?
                <Form.Group className="mb-3" controlId="error">
                    <Form.Label>Error message</Form.Label>
                    <Form.Control as="textarea" rows={3} name="errorMessage" value={information.errorMessage} onChange={handleInfo}/>
                </Form.Group>
            :undefined}

            {toShow.includes("additionalInfo2")?
                <Form.Group className="mb-3">
                    <Form.Label>Additional information (critical to help us resolve the issue!)</Form.Label>

                    <Form.Control className="my-2" type="text" placeholder="please provide a link to download the input data" name="inputDataThatTrggerError" value={information.inputDataThatTrggerError} onChange={handleInfo}/>

                    <Form.Control className="my-2" type="text" placeholder="please provide a link to screenshots if you have" name="screenshots" value={information.screenshots} onChange={handleInfo}/>
                </Form.Group>
            :undefined}

            {toShow.includes("documentation")?      
                <Form.Group className="mb-3">
                    <Form.Check aria-label="option 1"   >
                        <Form.Check.Input name="documentationRead" type="checkbox" checked={information.documentationRead} onChange={handleInfo}/>
                        <Form.Check.Label>
                            I have read the online help: 
                            <a href="https://3dt.pages.fraunhofer.de/cuttlefish/" target="_blank" rel="noreferrer" >Cuttlefish online documentation</a>
                        </Form.Check.Label>
                    </Form.Check> 

                    <Form.Control type="text" placeholder="question of documentation" name="questionOfDocumentation" value={information.questionOfDocumentation} onChange={handleInfo}/>
                </Form.Group>
            :undefined}


            {toShow.includes("otherQuestion")?
                <Form.Group className="mb-3" controlId="others">
                    <Form.Label>Other questions</Form.Label>
                    <Form.Control as="textarea" rows={5} name="otherQuestions" value={information.otherQuestions} onChange={handleInfo}/>
                </Form.Group>
            :undefined}


            <Button variant="secondary mt-5" type="submit" disabled={blocked}>
                Submit
            </Button>
        
        </Form>

    </Container>
    )
}