import { Box, Container, Divider, Typography } from "@material-ui/core";
import releases from "../data/releases/releaseNotes.js";
import ReactMarkdown from 'react-markdown';
import { useEffect, useState } from "react";
import rehypeRaw from 'rehype-raw';

export default function ReleaseHistory(){ 
    let [descriptions, setDescriptions] = useState(releases.map(release => null))
    
    function stripHeader(text) {
        const lines = text.replace(/\r\n?/g, '\n').split('\n')
        const headerEnd = lines.indexOf('---', 1);
        return lines.slice(headerEnd + 1).join('\n');
    }

    useEffect(() =>
        releases.forEach((release, idx) => {
            (async () => {
                const res = await fetch(release.description);
                const text = stripHeader(await res.text());

                setDescriptions(oldD => {
                    let newD = [...oldD];
                    newD[idx] = text;
                    return newD
                });
            })()
        }), 
        []
    )
    
    return(
        <Container>
            <Typography variant="h2">Release History</Typography>
            <Box sx={{paddingTop: "2em", paddingBottom: "2em"}}><Divider /></Box> 

            <div class="accordion" id="accordionExample">
            {
                releases.map((release, idx) =>
                <>
                    <div class="accordion-item">
                        <h2 class="accordion-header " id={`heading${idx}`}>
                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${idx}`} aria-expanded="true" aria-controls="collapseOne">
                                    <Typography variant="h5" className="text-primary"> 
                                        {release.title}
                                    </Typography>
                            </button>
                        </h2>
                        <div id={`collapse${idx}`} class="accordion-collapse collapse xshow" aria-labelledby={`heading${idx}`} data-bs-parent="#accordionExample">
                            <div class="accordion-body p-0">
                                <div class="card  m-0 shadow-sm bg-light">
                                    <div class="row g-0">
                                        <div class="xcol-md-12 ">
                                            <div class="card-body ">
                                                <Typography className="text-black  pb-2">{release.date}</Typography>
                                                <ReactMarkdown
                                                    rehypePlugins={[rehypeRaw]}
                                                >
                                                    {descriptions[idx]}
                                                </ReactMarkdown>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>
                </>
                )
            }
            </div>
   
        </Container>
    )
}