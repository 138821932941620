
import './Publications.css'
import { publications } from '../data/publications/Publications'
import { Box, Container, Divider, Typography } from '@material-ui/core'

function Publication(props) {
    const data = props.data;

    const resources = data.resources.map(resource => <>[<a rel="noreferrer" target="_blank" href={resource.url}>{resource.name}</a>]&nbsp;</>)
    
    return (
        <div class="card mb-3">
            <div class="row g-1  d-flex align-items-center">
                <div class="d-none d-sm-block xborder col-md-2 d-flex align-items-center" style={{"max-height": "30vh"}}>
                    <img src={data.imagePaper} class="img-fluid rounded paper_img_cover" alt="paper card"/>
                </div>
                <div class="col-md-10">
                    <div class="card-body">
                        <Box>
                            <Typography variant="h6" ><a href={data.file} target="_blank" rel="noreferrer">{data.title}</a></Typography>
                            <Typography className='xborder'>{data.authors}</Typography>
                            <Typography>{data.venue}</Typography>
                            <Typography>{data.submitDate}</Typography>
                            {/* <Box sx={{paddingTop: "2em"}}></Box> */}
                            <Typography align="justify" className="d-none d-sm-block">{data.abstract.length===0? "" : data.abstract.substring(0,350)+"..."}</Typography>
                            <Box sx={{paddingTop: {xs: "0em", md:"0.5em"}}}><Typography>{resources}</Typography></Box>
                        </Box>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default function Publications() {
    const publicationsByType = {}
    publications.forEach(publication => publicationsByType[publication.type] = [...publicationsByType[publication.type] || [],publication])
    return (<Container>
        {/* <Box sx={{paddingTop: "2em", paddingBottom: "2em"}}><Divider/></Box> */}
            <Typography variant="h2">Publications</Typography>
        <Box sx={{paddingTop: "2em", paddingBottom: "2em"}}><Divider /></Box>
        {
            ["Journal Articles", "Conference Papers", "Theses"].map((element, index) => {
                return  <>
                        <Box sx={{paddingBottom: "2em"}}>
                            <Typography variant="h4">{element}</Typography>
                        </Box>                        
                        {publicationsByType[element].map(function(publication) {
                            return  <><Typography variant="h6">
                                        <Publication data={publication}></Publication>
                                    </Typography>
                                    <Box sx={{paddingTop: "0.5em", paddingBottom: "0.5em"}}></Box>
                                    </>
                        })}
                        </>
            })
        }
    </Container>)
}